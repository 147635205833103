/* Toprenault HTML Template */


@import url('flaticon.css');
@import url('icofont.css');
@import url('dripicons-v2.css');
@import url('animate.css');
@import url('jquery-ui.css');
@import url('owl.css');
@import url('jquery.bootstrap-touchspin.css');


* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

@font-face {
  font-family: 'RenaultLife-Light';
  font-display: auto;
  src: url('../fonts/lat-cyr-regular.woff') format('truetype');
}

@font-face {
  font-family: 'RenaultLife-Regular';
  font-display: auto;
  src: url('../fonts/lat-cyr-regular.woff2') format('truetype');
}

@font-face {
  font-family: 'RenaultLife-Bold';
  font-display: auto;
  src: url('../fonts/lat-cyr-bold.woff') format('truetype');
}


@font-face {
  font-family: 'Read-Light';
  font-display: auto;
  src: url('../fonts/lat-cyr-bold.woff') format('truetype');
}

@font-face {
  font-family: 'Read-Regular';
  font-display: auto;
  src: url('../fonts/lat-cyr-bold.woff') format('truetype');
}

@font-face {
  font-family: 'Read-Medium';
  font-display: auto;
  src: url('../fonts/Read-Medium_V1100.ttf') format('truetype');
}

@font-face {
  font-family: 'Read-Bold';
  font-display: auto;
  src: url('../fonts/Read-Bold_V1100.ttf') format('truetype');
}

@font-face {
  font-family: 'Read-Italic';
  font-display: auto;
  src: url('../fonts/Read-Italic_V1100.ttf') format('truetype');
}






/*** 

====================================================================
	Global Settings
====================================================================

 ***/

body {
  font-size: 14px;
  font-weight: 400;
  /* line-height: 1.42857143; */
  background: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  font-family: 'RenaultLife-Regular';
  position: relative;
}



a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
  background-color: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
}

h4 {
  font-size: normal !important;
}

.strike-through {
  text-decoration: line-through;
}

.auto-container {
  position: static;
  max-width: 1170px;
  padding: 0px 15px;
  margin: 0 auto;
}

.small-container {
  max-width: 780px;
  margin: 0 auto;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.theme-btn,
.tp-caption .theme-btn {
  display: inline-block;
  text-align: center;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

#home-btn:hover {
  border-bottom: none !important;
  color: #000000;
}

.grey-bg {
  background-color: #f9f9f9;
}

.centered {
  text-align: center;
}

/*Btn Style One*/

.btn-style-one {
  position: relative;
  padding: 5px 20px;
  line-height: 24px;
  background: #fc3;
  border: 1px solid #fc3;
  color: #000;
  text-align: center;
  font-size: 13px;
  font-weight: 610;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.btn-style-one:hover {
  color: #fc3;
  background: #101010;
  border-color: #101010;
}

/*Btn Style Two*/

.btn-style-two {
  position: relative;
  padding: 12px 22px;
  line-height: 24px;
  color: #f7b100;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  background: none;
  letter-spacing: 1px;
  background-color: #ffffff;
  text-transform: uppercase;
  border: 1px solid #f2f2f2;
}

.btn-style-two .fa {
  margin-left: 8px;
}

.btn-style-two:hover {
  color: #ffffff;
  border-color: #f7b100;
  background-color: #f7b100;
}

/*Btn Style Three*/

.btn-style-three {
  position: relative;
  padding: 10px 35px;
  line-height: 24px;
  color: #f7b100;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  background: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 2px solid #f7b100;
}

.btn-style-three:hover {
  color: #ffffff;
  background: #f7b100;
  border-color: #f7b100;
}

/*Btn Style Four*/

.btn-style-four {
  position: relative;
  padding: 8px 20px;
  line-height: 24px;
  color: #282328;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  /* background:#f2f2f2; */
  /* background:#f2f2f2; */
  letter-spacing: 0px;
  text-transform: uppercase;
}

.btn-style-four:hover {
  color: #ffffff;
  background: #f7b100;
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title {
  position: relative;
  margin-bottom: 50px;
}

.sec-title h2 {
  padding-bottom: 7px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  font-family: 'RenaultLife-Regular';
  text-transform: lowercase;
}

.sec-title h2::first-letter {
  text-transform: uppercase;
}

.sec-title h2:before {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 55px;
  height: 2px;
  top: 30px;
}

.sec-title.centered {
  text-align: center;
}

.sec-title.centered h2:before {
  left: 50%;
  margin-left: -27px;
}

.sec-title.light h2 {
  color: #ffffff;
  border-color: #2f3335;
}

.sec-title.style-two h2 {
  border-color: #ececec;
}

.sec-title.no-border h2 {
  border: 0px;
}

/*** 

====================================================================
	List Style One
====================================================================

***/

.list-style-one {
  position: relative;
  margin-bottom: 25px;
}

.list-style-one li {
  position: relative;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  padding-left: 30px;
  margin-bottom: 14px;
}

.list-style-one li .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #f7b100;
  font-size: 16px;
}

/*** 

====================================================================
	List Style Two
====================================================================

***/

.list-style-two {
  position: relative;
  margin-bottom: 25px;
}

.list-style-two li {
  position: relative;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 6px;
}

.list-style-two li:before {
  position: absolute;
  content: '\f101';
  left: 0px;
  top: 0px;
  color: #f7b100;
  font-size: 16px;
  font-family: 'FontAwesome';
}

/*** 

====================================================================
	List Style Three
====================================================================

***/

.list-style-three {
  position: relative;
  margin-bottom: 25px;
}

.list-style-three li {
  position: relative;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 3px;
}

.list-style-three li .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #f7b100;
  font-size: 14px;
}

/*** 

====================================================================
	List Style Four
====================================================================

***/

.list-style-four {
  position: relative;
}

.list-style-four li {
  position: relative;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  padding-left: 25px;
  margin-bottom: 10px;
}

.list-style-four li:before {
  position: absolute;
  content: '\f0ad';
  left: 0px;
  top: 0px;
  color: #f7b100;
  font-size: 14px;
  font-family: 'FontAwesome';
}

/*** 

====================================================================
	List Style Five
====================================================================

***/

.list-style-five {
  position: relative;
}

.list-style-five li {
  position: relative;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  padding-left: 15px;
  margin-bottom: 10px;
  font-style: italic;
}

.list-style-five li:before {
  position: absolute;
  content: '\f105';
  left: 0px;
  top: 0px;
  color: #848484;
  font-size: 14px;
  font-style: normal;
  font-family: 'FontAwesome';
}

/*** 

====================================================================
	List Style Six
====================================================================

***/

.list-style-six {
  position: relative;
}

.list-style-six li {
  position: relative;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  padding-left: 45px;
  margin-bottom: 15px;
  line-height: 2em;
  padding-bottom: 15px;
  border-bottom: 1px solid #2f3335;
}

.list-style-six li .icon {
  position: absolute;
  left: 0px;
  top: 10px;
  color: #f7b100;
  font-size: 26px;
  line-height: 1em;
}

.list-style-six li .bold {
  position: relative;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
  font-family: 'Montserrat', sans-serif;
}

.theme_color {
  color: #f7b100;
}

/*Social Icon One*/

.social-icon-one {
  position: relative;
}

.social-icon-one li {
  position: relative;
  margin: 0px 7px;
  display: inline-block;
}

.social-icon-one li a {
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #848484;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #ececec;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-icon-one a:hover {
  color: #ffffff;
  border-color: #f7b100;
  background-color: #f7b100;
}

/*Social Icon Two*/

.social-icon-two {
  position: relative;
}

.social-icon-two li.share {
  position: relative;
  font-weight: 600;
  color: #232527;
  font-size: 16px;
  margin-right: 10px;
  margin-left: 0px;
  font-family: 'Montserrat', sans-serif;
}

.social-icon-two li {
  position: relative;
  margin-left: 8px;
  display: inline-block;
}

.social-icon-two li a {
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 38px;
  color: #848484;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #ececec;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-icon-two a:hover {
  color: #ffffff;
  border-color: #f7b100;
  background-color: #f7b100;
}

/*Social Icon Three*/

.social-icon-three {
  position: relative;
}

.social-icon-three li {
  position: relative;
  margin-right: 14px;
  display: inline-block;
}

.social-icon-three li a {
  position: relative;
  color: #9a9a9a;
  font-size: 14px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-icon-three li a:hover {
  color: #f7b100;
}

/*Social Icon four*/

.social-icon-four {
  position: relative;
  margin-top: 25px;
}

.social-icon-four li {
  position: relative;
  margin-right: 8px;
  margin-bottom: 10px;
  display: inline-block;
}

.social-icon-four li a {
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 36px;
  color: #848484;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid #2f3335;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-icon-four a:hover {
  color: #ffffff;
  border-color: #f7b100;
  background-color: #f7b100;
}

img {
  display: inline-block;
  max-width: 100%;
}

/* .preloader{ position:fixed; left:0px; top:0px; width:100%; height:100%; z-index:999999; background-color:#ffffff; background-position:center center; background-repeat:no-repeat; background-image:url(../images/icons/preloader.svg);}  */

/*** 

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  color: #ffce33;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 48px;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  background: #26292b;
  display: none;
  font-weight: 700;
  border: 1px solid #2f3335;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top:hover {
  color: #000000;
  background: #f7b100;
  border-color: #f7b100;
}

/*** 

====================================================================
	Main Header style
====================================================================

***/

.main-header {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sticky-header {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0px 0px;
  background: #ffffff;
  z-index: 0;
  border-bottom: 1px solid #cccccc;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sticky-header .logo {
  padding: 2px 0px;
}

.fixed-header .sticky-header {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -op-animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -ms-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -op-animation-duration: 500ms;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -op-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -ms-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -op-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.main-header .header-top {
  position: relative;
  padding: 12px 0px;
  background-color: #232628;
}

.main-header .header-top .top-left {
  position: relative;
  float: left;
}

.main-header .header-top .top-left .top-links,
.main-header .header-top .top-left .social-links {
  position: relative;
  display: inline-block;
}

.main-header .header-top .top-left li.location {
  position: relative;
  padding-right: 16px;
  border-right: 1px solid #35393b;
}

.main-header .header-top .top-left li.location a {
  position: relative;
  background: none;
  border: 0px;
  color: #848484;
  font-size: 14px;
  padding: 0px;
  font-family: 'Open Sans', sans-serif;
}

.main-header .header-top .top-left li.location a .icon {
  position: relative;
  top: 1px;
  color: #f7b100;
  font-size: 16px;
  margin-right: 10px;
}

.main-header .header-top .top-left li.location .dropdown-menu {
  top: 100%;
  width: 170px;
  border-radius: 0px;
  padding: 0px;
  margin-top: 13px;
  background-color: #222222;
  border: none;
  border-top: 2px solid #f7b100;
}

.main-header .header-top .top-left li.location .dropdown-menu>li>a {
  padding: 10px 15px !important;
  color: #ffffff !important;
  text-align: left;
  font-size: 13px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-header .header-top .top-left li.location .dropdown-menu>li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.main-header .header-top .top-left li.location .dropdown-menu>li:last-child {
  border: none;
}

.main-header .header-top .top-left li.location .dropdown-menu>li>a:hover {
  color: #ffffff;
  background-color: #f7b100;
}

.main-header .header-top .top-left .social-links a {
  display: inline-block;
  margin-left: 16px;
  line-height: 30px;
  color: #848484;
  font-size: 14px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-header .header-top .top-left .social-links a:hover {
  color: #f7b100;
}

.main-header .header-top .top-right {
  position: relative;
  float: right;
  padding: 0px;
}

.main-header .header-top .top-right ul>li {
  position: relative;
  display: inline-block;
  margin-left: 8px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.50);
}

.main-header .header-top .top-right ul>li>a {
  position: relative;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.50);
}

.main-header .header-top .top-right .social-links {
  display: inline-block;
}

.main-header .header-top .top-right .social-links a {
  display: inline-block;
  margin-left: 12px;
  line-height: 30px;
  color: #848484;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.main-header .header-top .top-right .sell-car {
  color: #ffffff;
  font-size: 14px;
  padding: 0px 10px;
  line-height: 2em;
  display: inline-block;
  background-color: #f7b100;
}

.main-header .dropdown-option {
  display: inline-block;
}

.main-header .dropdown-option li.account {
  border: 0px;
  padding-right: 0px;
  line-height: 1.4em;
}

.main-header .account .dropdown-menu {
  top: 100%;
  width: 170px;
  border-radius: 0px;
  padding: 0px;
  margin-top: 10px;
  background-color: #222222;
  border: none;
  border-top: 2px solid #f7b100;
}

.main-header .dropdown-option li.account>a {
  border: 0px;
  padding: 0px;
  font-size: 14px;
  background: none;
  font-weight: 300;
  border-radius: 0px;
  padding-left: 10px;
  padding-right: 10px;
  color: #848484 !important;
  font-family: 'Open Sans', sans-serif;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-header .dropdown-option li.account>a>.icon {
  color: #f7b100;
}

.main-header .account .dropdown-menu>li {
  padding-right: 0px !important;
  margin: 0px !important;
  float: none;
  display: block !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
}

.main-header .account .dropdown-menu>li:last-child {
  border-bottom: none;
}

.main-header .account .dropdown-menu>li>a {
  padding: 5px 15px !important;
  color: #ffffff !important;
  text-align: left;
  font-size: 13px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-header .account .dropdown-menu>li>a:hover {
  color: #ffffff;
  background: #f7b100;
}

.main-header .header-upper {
  position: relative;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  z-index: 5;
}

.main-header .header-upper .upper-inner {
  padding: 0px 0px 0px 5px;

}

.main-header .logo-outer {
  position: relative;
  float: left;
  z-index: 25;
  padding: 18px 0px;
}

.main-header .logo-outer .logo img {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.main-header .header-upper .upper-right {
  position: relative;
  float: right;
}

.main-header .header-upper .nav-outer {
  position: relative;
  padding-right: 110px;
}

.main-header .nav-outer .more-options {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -14px;
}

.main-header .nav-outer .more-options .cart-box {
  position: relative;
  padding-left: 20px;
  border-left: 1px solid #ebebeb;
}

.main-header .nav-outer .more-options .cart-box a {
  position: relative;
  color: #232527;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.main-header .nav-outer .more-options .cart-box a .icon {
  margin-left: 6px;
  font-size: 16px;
}

.main-header .nav-outer .more-options .cart-box a .icon .number {
  position: absolute;
  right: -9px;
  top: -4px;
  width: 16px;
  height: 16px;
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  background-color: #f7b100;
}

.main-header .header-lower {
  position: relative;
}

.main-header .header-lower .lower-inner {
  position: relative;
  background-color: #f9f9f9;
}

/*Search Box Widget*/

.main-header .header-lower .lower-inner .search-box .form-group {
  position: relative;
  margin: 0px;
}

.main-header .header-lower .lower-inner .search-box .form-group input[type="text"],
.main-header .header-lower .lower-inner .search-box .form-group input[type="search"] {
  position: relative;
  line-height: 26px;
  padding: 10px 50px 10px 20px;
  background: none;
  display: block;
  font-size: 14px;
  width: 100%;
  height: 60px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-header .header-lower .lower-inner .search-box .form-group button {
  position: absolute;
  right: 0px;
  top: 15px;
  height: 30px;
  width: 55px;
  display: block;
  font-size: 18px;
  color: #848484;
  line-height: 100%;
  font-weight: normal;
  background: none;
  border-left: 1px solid #ebebeb;
}

.main-header .header-lower .lower-inner .search-box {
  max-width: 325px;
  width: 100%;
}

/*Info Block*/

.main-header .header-lower .lower-inner .info-block,
.main-header .header-lower .lower-inner .search-box {
  position: relative;
  display: inline-block;
}

.info-block li {
  position: relative;
  color: #848484;
  font-size: 14px;
  padding-left: 28px;
  margin-left: 20px;
  padding-top: 18px;
  padding-bottom: 17px;
  display: inline-block;
  padding-right: 20px;
  text-transform: uppercase;
  border-right: 1px solid #ebebeb;
}

.info-block li .icon {
  position: absolute;
  left: 0px;
  top: 20px;
  color: #f7b100;
  font-size: 20px;
  line-height: 1em;
}

.main-menu {
  position: relative;
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navigation {
  position: relative;
  margin: 0px;
}

.main-menu .navigation>li {
  position: relative;
  float: left;
  margin-right: 28px;
}

.main-menu .navigation>li:last-child {
  margin-right: 0px;
}

.sticky-header .main-menu .navigation>li {
  padding: 0px;
  margin-right: 0px;
}

.main-menu .navigation>li>a {
  position: relative;
  display: block;
  padding: 39px 0px;
  font-size: 14px;
  color: #232527;
  line-height: 30px;
  font-weight: 700;
  opacity: 1;
  text-transform: uppercase;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  font-family: 'Montserrat', sans-serif;
}

.main-menu .navigation>li.dropdown a,
.header-style-three .main-menu .navigation>li.dropdown>a {
  padding-right: 15px;
}

.main-header .main-menu .navigation>li.dropdown>a:before {
  font-family: 'FontAwesome';
  content: "\f107";
  position: absolute;
  right: 0px;
  font-size: 13px;
  line-height: 30px;
}

.sticky-header .main-menu .navigation>li>a {
  padding: 13px 15px !important;
  color: #333333;
}

.sticky-header .main-menu .navigation>li.dropdown>a:before {
  display: none;
}

.header-upper .main-menu .navigation>li:hover>a,
.header-upper .main-menu .navigation>li.current>a,
.header-upper .main-menu .navigation>li.current-menu-item>a {
  color: #f7b100;
  opacity: 1;
}

.sticky-header .main-menu .navigation>li:hover>a,
.sticky-header .main-menu .navigation>li.current>a,
.sticky-header .main-menu .navigation>li.current-menu-item>a {
  color: #ffffff !important;
  background: #f7b100;
}

.main-menu .navigation>li:hover>a:after {
  opacity: 1;
}

.main-menu .navigation>li>ul {
  position: absolute;
  left: 0px;
  top: 120%;
  width: 235px;
  z-index: 100;
  display: none;
  padding: 10px 28px;
  background: #ffffff;
  border-top: 2px solid #f7b100;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation>li>ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation>li>ul>li {
  position: relative;
  width: 100%;
  border-bottom: 1px dashed #dfdfdf;
}

.main-menu .navigation>li>ul>li:last-child {
  border-bottom: none;
}

.main-menu .navigation>li>ul>li>a {
  position: relative;
  display: block;
  padding: 12px 0px;
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #232527;
  text-align: left;
  text-transform: capitalize;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  font-family: 'Open Sans', sans-serif;
}

.main-menu .navigation>li>ul>li:hover>a {
  color: #f7b100;
}

.main-menu .navigation>li>ul>li.dropdown>a:after {
  font-family: 'FontAwesome';
  content: "\f105";
  position: absolute;
  right: 0px;
  top: 12px;
  width: 10px;
  height: 20px;
  display: block;
  line-height: 21px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  z-index: 5;
}



.main-menu .navigation>li>ul>li>ul {
  position: absolute;
  left: 115.5%;
  top: 20px;
  width: 235px;
  z-index: 100;
  display: none;
  padding: 10px 28px;
  background: #ffffff;
  border-top: 2px solid #f7b100;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation>li>ul>li>ul>li {
  position: relative;
  width: 100%;
  border-bottom: 1px dashed #dfdfdf;
}

.main-menu .navigation>li>ul>li>ul>li:last-child {
  border-bottom: none;
}

.main-menu .navigation>li>ul>li>ul>li>a {
  position: relative;
  display: block;
  padding: 12px 0px;
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #232527;
  text-align: left;
  text-transform: capitalize;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  font-family: 'Open Sans', sans-serif;
}

.main-menu .navigation>li>ul>li>ul>li>a:hover {
  color: #f7b100;
}

.main-menu .navigation>li.dropdown:hover>ul {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.main-menu .navigation li>ul>li.dropdown:hover>ul {
  visibility: visible;
  opacity: 1;
  top: 0px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 9px;
  width: 34px;
  height: 30px;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
  display: none;
}

/*** 

====================================================================
	Main Slider One
====================================================================

***/

.main-slider {
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.main-slider .tp-caption {
  z-index: 5;
}

.main-slider .slider-content {
  position: relative;
  padding: 35px 30px;
  background-color: rgba(0, 0, 0, 0.40);
}

.main-slider .slider-content h2 {
  position: relative;
  color: #ffffff;
  font-size: 40px;
  line-height: 1.4em;
  font-weight: 700;
  margin-bottom: 12px;
}

.main-slider .slider-content h2:before {
  position: absolute;
  content: '';
  left: -30px;
  top: 8px;
  width: 5px;
  height: 85px;
  background-color: #f7b100;
}

.main-slider .slider-content .text {
  font-weight: 400;
  color: #ffffff;
  font-size: 18px;
  line-height: 1.8em;
  margin-bottom: 25px;
}

.main-slider .tp-dottedoverlay {
  background: rgba(0, 0, 0, 0.10) !important;
}

.main-slider .tp-bannertimer,
.main-slider .tp-bullets {
  display: none !important;
}

.main-slider .uranus.tparrows {
  background-color: rgba(0, 0, 0, 0.30);
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.main-slider .uranus.tparrows:before {
  width: auto;
  height: auto;
  font-size: 20px;
  color: #f7de00;
}

.main-slider .uranus.tparrows:hover {
  color: #f7b100 !important;
  background-color: grey;
}

.main-slider .uranus.tparrows:hover::before {
  opacity: 1;
  color: #f7b100 !important;
}

/*** 

====================================================================
	Fancy Box
====================================================================

***/

.fancybox-next span,
.fancybox-prev span {
  background-image: none !important;
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
  text-align: center;
}

.fancybox-next span:before,
.fancybox-prev span:before {
  content: '';
  position: absolute;
  font-family: 'FontAwesome';
  left: 0px;
  top: 0px;
  font-size: 12px;
  width: 44px !important;
  height: 44px !important;
  line-height: 44px !important;
  background-color: rgba(28, 28, 28, 0.40) !important;
  color: #ffffff;
  visibility: visible;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.fancybox-next span:before {
  content: '\f178';
}

.fancybox-prev span:before {
  content: '\f177';
}

.fancybox-next:hover span:before,
.fancybox-prev:hover span:before {
  background-color: #ffffff !important;
  color: #000000;
}

.fancybox-type-image .fancybox-close {
  right: 0px;
  top: 0px;
  width: 45px;
  height: 45px;
  background: url(../images/icons/icon-cross.png) center center no-repeat;
  background-color: rgba(17, 17, 17, 0.50) !important;
}

.fancybox-type-image .fancybox-close:hover {
  background-color: #000000 !important;
}

.fancybox-type-image .fancybox-skin {
  padding: 0px !important;
}

/*** 

====================================================================
	Car Search Form
====================================================================

***/

.car-search-form {
  position: relative;
  z-index: 10;
  font-family: Read-Light;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3e;
  font-weight: bold;
}

.car-search-form .inner-section {
  position: relative;
  /* margin-top:-70px; */
  /* margin-top: -25px; */
  margin-top: -200px;
}

.car-search-form .car-search-tab .prod-tabs {
  position: relative;
}

.car-search-form .car-search-tab .prod-tabs .tab-btns {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #eeeeee;
}

.car-search-form .car-search-tab .prod-tabs .tab-btns .tab-btn {
  position: relative;
  display: block;
  float: left;
  margin-right: 0px;
  font-size: 14px;
  color: #000;
  border-top: 3px solid #f7b100;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  background-color: #f7b100;
  padding: 9px 27px 9px;
  transition: all 500ms ease;
}

.car-search-form .car-search-tab .prod-tabs .tab-btns .tab-btn:hover::after,
.car-search-form .car-search-tab .prod-tabs .tab-btns .tab-btn.active-btn:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
}

.car-search-form .car-search-tab .prod-tabs .tab-btns .tab-btn:hover,
.car-search-form .car-search-tab .prod-tabs .tab-btns .tab-btn.active-btn {
  color: #232527;
  background: #ffffff;
}

.car-search-form .car-search-tab .prod-tabs .tabs-content {
  position: relative;
  /* padding:20px 20px 0px 20px; */
  /* border:1px solid #f0f0f0; */
  background-color: #ffffff;
  border-top: 0px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  /* -webkit-box-shadow: 0px 0px 12px 0px rgba(240,240,240,1);
	-moz-box-shadow: 0px 0px 12px 0px rgba(240,240,240,1);
	box-shadow: 0px 0px 12px 0px rgba(240,240,240,1); */
}

.car-search-form .car-search-tab .prod-tabs .tabs-content .tab {
  position: relative;
  display: none;
  border-top: 0px;
}

.car-search-form .car-search-tab .prod-tabs .tabs-content .tab.active-tab {
  display: block;
}

/*Car Search Form*/

.car-search-form .row {
  margin: 0px -10px;
}

.car-search-form .column {
  padding: 0px 10px;
}

.car-search-form .form-group {
  /* margin-bottom: 20px; */
  padding: 0px 10px;
}

.car-search-form input[type="text"],
.car-search-form input[type="search"],
.car-search-form input[type="email"],
.car-search-form input[type="password"],
.car-search-form select,
.car-search-form textarea {
  display: block;
  width: 100%;
  height: 49px;
  font-size: 16px;
  color: #848484;
  line-height: 30px;
  /* padding:10px 20px; */
  font-weight: 300;
  background-color: #f7f7f7;
  border: 1px solid #f2f2f2;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.car-search-form input[type="search"] {
  display: block;
  width: 100%;
  height: 45px !important;
  font-size: 16px;
  color: #848484;
  line-height: 30px;
  /* padding:10px 20px; */
  font-weight: 300;
  background-color: #f7f7f7;
  border: 1px solid #f2f2f2;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}



.car-search-form input:focus,
.car-search-form select:focus,
.car-search-form textarea:focus {
  border-color: #ff6600;
}

.car-search-form textarea {
  height: 150px;
  resize: none;
  padding: 12px 25px;
}

.car-search-form .search-btn {
  position: relative;
  width: 100%;
  height: 45px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  background: #f7b100;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 2px solid #f7b100;
  font-family: 'Montserrat', sans-serif;
}

.car-search-form .form-group .ui-selectmenu-button.ui-button {
  background-color: #f7f7f7;
}

.car-search-form .form-group .ui-selectmenu-button.ui-button:focus {
  background-color: #ffffff;
  border-color: #f7b100;
}

/*Custom Select*/

.form-group .ui-selectmenu-button.ui-button {
  width: 100%;
  font-size: 14px;
  font-style: normal;
  height: 45px;
  padding: 10px 18px;
  line-height: 24px;
  color: #333333;
  border-radius: 0px;
  border: 1px solid #e5e5e5;
  background: #ffffff;
}

.form-group .ui-button .ui-icon {
  background: none;
  position: relative;
  top: 0px;
  text-indent: 0px;
  color: #333333;
}

.form-group .ui-button .ui-icon:before {
  font-family: 'FontAwesome';
  content: "\f107";
  position: absolute;
  right: 0px;
  top: 2px !important;
  top: 13px;
  width: 10px;
  height: 20px;
  display: block;
  color: #333333;
  line-height: 20px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  z-index: 5;
}

.ui-widget.ui-widget-content {
  border: 1px solid #e0e0e0;
  font-family: 'Montserrat', sans-serif;
}

.ui-menu .ui-menu-item {
  font-size: 14px;
  border-bottom: 1px solid #e0e0e0;
}

.ui-menu .ui-menu-item:last-child {
  border: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active {
  background: #f7b100;
  border-color: #f7b100;
}

.ui-menu .ui-menu-item-wrapper {
  position: relative;
  display: block;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 24px;
}

.ui-menu-item:hover {
  background-color: #17519d;
}

/*** 

====================================================================
	Popular Cars Section
====================================================================

***/

.popular-cars-section {
  position: relative;
  padding: 60px 0px 30px;
}

.car-block {
  position: relative;
  margin-bottom: 25px;

}

.car-block .inner-box {
  position: relative;
  /* border:1px solid #f2f2f2;
	-webkit-box-shadow:  0 0 0px 1px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1); */
  /* width: 242px;
	height: 376px; */
  border-radius: 0;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #e6e6ec;
  background-color: #ffffff;
  cursor: pointer;

}

.car-block .inner-box:hover {
  /* transform: scale(1.05) !important;
	border: 1px solid #848484;
	box-shadow: 0 0 20px rgba(41, 46, 49, 0.21);
	-webkit-transition: border 0.3s linear;
		   -moz-transition: all 0.3s linear;
			 -o-transition: all 0.3s linear;
			-ms-transition: all 0.3s linear;
				transition: all 0.3s linear; */
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e6e6ec;
  background-color: #f3f5f6;
}

.car-block .inner-box:hover .image .price {
  color: #f7de00;
  background-color: #000000;
}

.car-block .inner-box:hover .image .price:before {
  border-bottom-color: #000000;
}

.car-block .inner-box .image {
  position: relative;
  text-align: center;

  cursor: pointer;
}

.customPoprange {
  border-radius: 0px !important;
  margin-left: 280px !important;
  margin-top: 51px !important;
  min-width: 340px !important;
  border: none;
  padding: 0px !important;
  transform: none !important;
}

.customPopKMrange {
  border-radius: 0px !important;
  margin-left: 488px !important;
  margin-top: 51px !important;
  min-width: 340px !important;
  border: none;
  padding: 0px !important;
  transform: none !important;
}

.customPopGeorange {
  border-radius: 0px !important;
  margin-left: 696px !important;
  margin-top: 51px !important;
  min-width: 340px !important;
  border: none;
  padding: 0px !important;
  transform: none !important;
}

.customPoprange>.arrow,
.customPoprange>.arrow:after,
.customPopKMrange>.arrow,
.customPopKMrange>.arrow:after,
.customPopGeorange>.arrow,
.customPopGeorange>.arrow:after {
  position: absolute;
  display: none !important;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: none;
}


.customPopCriteres.bottom {
  margin-top: 15px !important;
}

.customPopCriteres>.arrow {
  left: 1060px !important;
}


.customPoprange>.popover-content,
.customPopKMrange>.popover-content,
.customPopGeorange>.popover-content {
  padding: 9px 0px 0px 0px !important;
  font-family: 'Read-Medium' !important;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.2);
}

/* .customPoprange.bottom, .customPopKMrange.bottom, .customPopGeorange.bottom  {
  
} */

.ng5-slider .ng5-slider-selection {
  background: #000 !important;
}

.ng5-slider .ng5-slider-pointer {
  background: #fff !important;
  border: 1px solid #000;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, .1);

}


.ng5-slider .ng5-slider-pointer:after {
  content: '';
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  top: 1px !important;
  left: 1px !important;
  border-radius: 16px !important;
  background: #fff;
}

.ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
  background-color: grey !important;
}


.car-block .inner-box .image .price {
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: #232527;
  font-size: 18px;
  font-weight: 400;
  padding: 5px 16px 5px 10px;
  background-color: #f7f7f7;
  font-family: 'Montserrat', sans-serif;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}



.car-block .inner-box .image .price:before {
  position: absolute;
  content: '';
  left: -25px;
  top: 0px;
  border-bottom: 35px solid #f7f7f7;
  border-left: 25px solid transparent;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.car-block .inner-box h3 {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  /* padding:16px 20px 2px 20px; */
  /* padding: 16px 16px 2px 5px; */
  /* text-transform:uppercase; */
}

.car-block .inner-box h5 {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  font-weight: 500;
  /* padding:2px 20px 10px 20px; */

}

.car-block .inner-box h5 a {
  color: #f7de00;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.car-block .inner-box h3 a {
  color: #232628;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  cursor: pointer;
}

.car-block .inner-box h3 a:hover {
  color: #232628;
  ;
}

.car-block .inner-box .lower-box {
  position: relative;
  padding: 10px 1px;
  /* margin: 0 8px; */
  background-color: #f7f7f7;
}

.car-block .inner-box .lower-box .car-info {
  position: relative;
}

.car-block .inner-box .lower-box .car-info li {
  position: relative;
  color: #848484;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 10px;
  display: inline-block;
  border-right: 1px solid #eaeaea;
}

.car-block .inner-box .lower-box .car-info li .icon {
  position: absolute;
  left: 0px;
  top: 0px;
}

.car-block .inner-box .lower-box .car-info li:last-child {
  margin-right: 0px;
  padding-right: 0px;
  border: 0px;
}

/*** 

====================================================================
	Counter Section
====================================================================

***/

.counter-section {
  position: relative;
  padding: 80px 0px 40px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.counter-section:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 38, 40, 0.96)
}

/*fact counter*/

.fact-counter {
  position: relative;
}

.fact-counter .column {
  position: relative;
  margin-bottom: 40px;
}

.fact-counter .column .inner {
  position: relative;
  text-align: center;
}

.fact-counter .column .inner .content {
  position: relative;
  display: inline-block;
}

.fact-counter .column:last-child .inner {
  border-right: 0px;
}

.fact-counter .column .inner .icon-box {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #fe5807;
  font-size: 42px;
  text-align: center;
  line-height: 1em;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.fact-counter .count-outer .percentage {
  display: inline-block;
  font-weight: 700;
  color: #ffffff;
  font-size: 40px;
  line-height: 1em;
}

.fact-counter .column .inner .count-outer {
  position: relative;
  font-weight: 700;
  color: #ffffff;
  font-size: 36px;
  line-height: 1em;
  padding-left: 60px;
  display: inline-block;
}

.fact-counter .count-outer .count-text {
  position: relative;
  font-weight: 700;
  color: #ffffff;
  font-size: 36px;
  line-height: 1em;
  font-family: 'Montserrat', sans-serif;
}

.fact-counter .column .counter-title {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 25px;
  padding: 12px 30px;
  background-color: #292d2f;
  text-transform: capitalize;
  border: 1px solid #303436;
  font-family: 'Montserrat', sans-serif;
}

.fact-counter .column:hover .icon-box {
  border-color: #f47629;
}


/*** 

====================================================================
	Popular Cras Section Two
====================================================================

***/

.popular-cars-section-two {
  position: relative;
  padding: 65px 0px 40px;
}



.popular-used-car .prod-tabs {
  position: relative;
}

.popular-used-car .prod-tabs .tab-btns {
  position: relative;
  z-index: 1;
  margin-bottom: 48px;
  text-align: center;
  display: inline-block;
  border: 1px solid #f2f2f2;
}

.popular-used-car .prod-tabs .tab-btns .tab-btn {
  position: relative;
  display: block;
  float: left;
  cursor: pointer;
  background-color: #ffffff;
  border-right: 1px solid #f2f2f2;
  transition: all 500ms ease;
  font-family: 'Open Sans', sans-serif;
}

.popular-used-car .prod-tabs .tab-btns .tab-btn .text {
  position: relative;
  display: block;
  float: left;
  margin-right: 0px;
  font-size: 14px;
  color: #232527;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  /*background-color:#f7b100;*/
  padding: 17px 40px 17px;
  transition: all 500ms ease;
  font-family: 'Open Sans', sans-serif;
}

.popular-used-car .prod-tabs .tab-btns .tab-btn:after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: -10px;
  opacity: 0;
  margin-left: -5px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid #f7b100;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.popular-used-car .prod-tabs .tab-btns .tab-btn:last-child {
  border-right: 0px;
}

.popular-used-car .prod-tabs .tab-btns .tab-btn .icon {
  position: absolute;
  right: -5px;
  top: 10px;
  line-height: 1em;
  font-size: 40px;
  color: rgba(8, 8, 8, 0.06);
}

.popular-used-car .prod-tabs .tab-btns .tab-btn:last-child .icon {
  right: 0px;
}

.popular-used-car .prod-tabs .tab-btns .tab-btn:hover:after,
.popular-used-car .prod-tabs .tab-btns .tab-btn.active-btn:after {
  bottom: -12px;
  opacity: 1;
}

.popular-used-car .prod-tabs .tab-btns .tab-btn:hover .icon,
.popular-used-car .prod-tabs .tab-btns .tab-btn.active-btn .icon {
  color: rgba(255, 255, 255, 0.15);
}

.popular-used-car .prod-tabs .tab-btns .tab-btn:hover,
.popular-used-car .prod-tabs .tab-btns .tab-btn.active-btn {
  background: #ff5908;
}

.popular-used-car .prod-tabs .tab-btns .tab-btn:hover .text,
.popular-used-car .prod-tabs .tab-btns .tab-btn.active-btn .text {
  color: #000000;
}

.popular-used-car .prod-tabs .tabs-content {
  position: relative;
}

.popular-used-car .prod-tabs .tabs-content .tab {
  position: relative;
  display: none;
}

.popular-used-car .prod-tabs .tabs-content .tab.active-tab {
  display: block;
}

.city-block {
  position: relative;
  margin-bottom: 30px;
}

.city-block .inner-box {
  position: relative;
  text-align: center;
  padding: 28px 0px 25px;
  border: 1px solid #f2f2f2;
}

.city-block .inner-box .icon-box {
  position: relative;
  width: 80px;
  height: 80px;
  color: #aeaeae;
  font-size: 46px;
  line-height: 74px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 16px;
  border: 1px solid #f2f2f2;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.city-block .inner-box h3 {
  position: relative;
  color: #232527;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.city-block .inner-box h3 a {
  color: #232527;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.city-block .inner-box h3 a:hover {
  color: #f7b100;
}

.city-block .inner-box:hover .icon-box {
  color: #ffffff;
  border-color: #f7b100;
  background-color: #f7b100;
}

.popular-used-car .brand-block {
  position: relative;
  margin-bottom: 30px;
}

.popular-used-car .brand-block .inner-box {
  position: relative;
  text-align: center;
  border: 1px solid #f2f2f2;
}

.popular-used-car .brand-block .inner-box:hover {
  border-color: #f7b100;
}

.popular-used-car .brand-block .inner-box img {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.popular-used-car .price-block {
  position: relative;
  margin-bottom: 30px;
}

.popular-used-car .price-block .inner-box {
  position: relative;
  text-align: center;
  border: 1px solid #f2f2f2;
}

.popular-used-car .price-block .inner-box:hover {
  border-color: #f7b100;
}

.popular-used-car .price-block a.text {
  position: relative;
  display: block;
  padding: 70px 10px;
  line-height: 28px;
  color: #232527;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.popular-used-car .price-block a.text:hover {
  color: #f7b100;
}

.popular-used-car .body-block {
  position: relative;
  margin-bottom: 30px;
}

.popular-used-car .body-block .inner-box {
  position: relative;
  text-align: center;
  border: 1px solid #f2f2f2;
}

.popular-used-car .body-block .inner-box:hover {
  border-color: #f7b100;
}

.popular-used-car .body-block .link-box {
  position: relative;
  display: block;
  color: #232527;
  padding: 25px 10px 35px;
  line-height: 30px;
}

.popular-used-car .body-block .link-box:hover {
  color: #f7b100;
}

.popular-used-car .body-block .icon-box {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.popular-used-car .body-block .link-box .text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 30px;

}

/*Services Section*/

.services-section {
  position: relative;
  padding: 90px 0px 50px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.services-block {
  position: relative;
  margin-bottom: 40px;
}

.services-block .inner-box {
  position: relative;
}

.services-block .inner-box .upper-box {
  position: relative;
  padding: 35px 30px 30px;
  background-repeat: no-repeat;
  background-size: cover;
}

.services-block .inner-box .upper-box:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.65);
}

.services-block .inner-box .upper-box .upper-content {
  position: relative;
  padding-left: 80px;
}

.services-block .inner-box .upper-box .upper-content .icon-box {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #ffffff;
  font-size: 50px;
  line-height: 1em;
}

.services-block .inner-box .upper-box .upper-content h2 {
  position: relative;
  color: #ffffff;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 15px;
}

.services-block .inner-box .upper-box .upper-content h2 a {
  color: #ffffff;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.services-block .inner-box .upper-box .upper-content h2 a:hover {
  color: #ff5908;
}

.services-block .inner-box .upper-box .upper-content .text {
  position: relative;
  color: #ffffff;
  font-size: 14px;
  line-height: 2em;
}

.services-block .inner-box .lower-content {
  position: relative;
  background-color: #ffffff;
}

.services-block .inner-box .lower-content {
  position: relative;
}

.services-block .inner-box .lower-content ul {
  position: relative;
  padding: 0px 15px;
}

.services-block .inner-box .lower-content ul li {
  position: relative;
  color: #848484;
  font-size: 14px;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: inline-block;
  padding-right: 30px;
  margin-right: 25px;
  line-height: 1.7em;
  border-right: 1px solid #f2f2f2;
}

.services-block .inner-box .lower-content ul li:last-child {
  border: 0px;
  margin-right: 0px;
  padding-right: 0px;
}

.services-block .inner-box .lower-content ul li .icon {
  position: absolute;
  left: 0px;
  top: 22px;
  color: #ff6012;
  font-size: 36px;
  line-height: 1em;
}

/*Comparison Section*/

.comparison-section {
  position: relative;
  padding: 70px 0px 40px;
}

.comparison-section .sec-title .btn-outer {
  position: relative;
  padding-left: 30px;
  margin-top: -50px;
  background-color: #ffffff;
}

.comparison-block {
  position: relative;
  margin-bottom: 30px;
}

.comparison-block .inner-box {
  position: relative;
  border: 1px solid #f2f2f2;
}

.comparison-block .inner-box .inner-car-block {
  position: relative;
  padding: 0px;
  border-right: 1px solid #f2f2f2;
}

.comparison-block .inner-box .inner-car-block:last-child {
  border: 0px;
}

.comparison-block .inner-box .vs {
  position: absolute;
  left: 50%;
  top: 20%;
  width: 35px;
  height: 35px;
  z-index: 1;
  color: #848484;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  line-height: 34px;
  margin-left: -18px;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.comparison-block .inner-box .inner-car-block .inner {
  position: relative;
}

.comparison-block .inner-box .inner-car-block .inner .image {
  position: relative;
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
}

.comparison-block .inner-box .inner-car-block .inner .lower-box {
  position: relative;
  padding: 14px 0px;
  text-align: center;
}

.comparison-block .inner-box .inner-car-block .inner .lower-box .car-name {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  color: #232527;
  margin-bottom: 0px;
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.comparison-block .inner-box .inner-car-block .inner .lower-box .price {
  position: relative;
  color: #848484;
  font-size: 16px;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.comparison-block .inner-box .inner-car-block .inner .lower-box .overlay-text {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  color: #f7b100;
  font-weight: 700;
  font-size: 14px;
  padding: 25px 0px;
  opacity: 0;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  text-decoration: underline;
}

.comparison-block .inner-box .overlay-link {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}

.comparison-block .inner-box:hover .vs {
  top: 100%;
  color: #ffffff;
  border-color: #f7b100;
  margin-top: -55px;
  background-color: #f7b100;
}

.comparison-block .inner-box:hover .inner-car-block .inner .lower-box .overlay-text {
  opacity: 1;
}

.comparison-block .inner-box:hover .inner-car-block .inner .lower-box .price,
.comparison-block .inner-box:hover .inner-car-block .inner .lower-box .car-name {
  opacity: 0;
  visibility: hidden;
}

/*Offer Section*/

.offer-section {
  position: relative;
  overflow: hidden;
  /* padding:65px 0px 70px;
	background-color:#232628; */
}

.offer-section .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.offer-section .owl-carousel .owl-stage-outer .owl-item {
  opacity: 0;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.offer-section .owl-carousel .owl-stage-outer .owl-item.active {
  opacity: 1;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.offer-block {
  position: relative;
}

.offer-block .inner-box {
  position: relative;
  border: 1px solid #3b404229;
  -webkit-box-shadow: 0 0 13px rgba(0, 0, 0, .25);
  box-shadow: 0 0 13px rgba(0, 0, 0, .25);
  border-radius: 12px;
}

.offer-block .inner-box .image {
  position: relative;
  text-align: center;
  background-color: #ffffff;
}

.offer-block .inner-box .image .price {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  padding: 6px 19px;
  background-color: #f7b100;
  font-family: 'Montserrat', sans-serif;

  /* position:absolute;
	left:8px;
	top:8px;
	color:#FFEB3B;
	font-size:24px;
	font-weight:700;
	padding:6px 19px;
	background-color:#000;
	border-radius:60%;
	font-family: 'Montserrat', sans-serif; */

}

.offer-block .inner-box .image .price .percent {
  position: relative;
  font-size: 12px;
  line-height: 1em;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
}

.offer-block .inner-box h3 {
  position: relative;
  color: #232527;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;

}

.offer-block .inner-box h3 a {
  color: #232527;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.offer-block .inner-box h3 a:hover {
  color: #f7b100;
}

.offer-block .inner-box .lower-box {
  position: relative;
  padding: 5px 15px 10px;
  background-color: #f7f7f7;
}

.offer-block .inner-box .lower-box .lower-price {
  position: relative;
  /* font-size:20px; */
  font-size: 27px;
  font-weight: 400;
  color: #f7b100;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.offer-block .inner-box .lower-box ul {
  position: relative;
}

.offer-block .inner-box .lower-box ul li {
  position: relative;
  color: #232527d6;
  font-size: 14px;
  padding-left: 24px;
  display: inline-block;
  padding-right: 10px;
  margin-right: 6px;
  line-height: 1em;
  border-right: 1px solid #ddd;
}

.offer-block .inner-box .lower-box ul li:last-child {
  padding-right: 0px;
  margin-right: 0px;
  border: 0px;
}

.offer-block .inner-box .lower-box ul li .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  color: rgba(0, 0, 0, 0.47);
  font-size: 16px;
}

.offer-block .inner-box .lower-box .plus-box {
  position: absolute;
  right: 20px;
  top: -10px;
  width: 25px;
  height: 25px;
  color: #848484;
  line-height: 24px;
  display: block;
  z-index: 1;
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
  /* background-color:#3b4042; */
  border: 1px solid #848484;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.offer-block .inner-box .lower-box .plus-box .icon {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: block;
  z-index: 99;
}

.offer-block .inner-box .lower-box .plus-box:after {
  position: absolute;
  content: '';
  left: 0px;
  width: 25px;
  height: 30px;
  top: -22px;
}



.offer-block .inner-box .lower-box .plus-box .tooltip-data {
  position: absolute;
  left: -26px;
  bottom: 100%;
  width: 200px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  padding: 20px 20px;
  margin-left: -60px;
  margin-bottom: 10px;
  background-color: #f7b100;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.offer-block .inner-box .lower-box .plus-box:hover {
  color: #ffffff;
  border-color: #f7b100;
  background-color: #f7b100;
}

.offer-block .inner-box .lower-box .plus-box:hover .tooltip-data {
  opacity: 1;
  visibility: visible;
  margin-bottom: 20px;
}

.offer-block .inner-box .lower-box .plus-box .tooltip-data:after {
  position: absolute;
  content: '';
  top: 100%;
  left: 50%;
  margin-left: -8px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #f7b100;
}

.offer-block .inner-box .lower-box .plus-box .tooltip-data li {
  position: relative;
  color: #ffffff;
  font-size: 14px;
  padding-left: 20px;
  margin-bottom: 10px;
  border: 0px;
  display: block;
}

.offer-block .inner-box .lower-box .plus-box .tooltip-data li:before {
  position: absolute;
  content: '\f00c';
  left: 0px;
  top: 0px;
  color: #ffffff;
  font-size: 14px;
  font-family: 'FontAwesome';
}


.offer-section .owl-nav {
  display: block;
}

.offer-section .owl-dots {
  text-align: center;
}

.offer-section .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  margin: 30px 4px;
  padding: 3px;
  /* transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    -ms-transition: all 0.6s ease;
    -o-transition: all 0.6s ease; */
}


.offer-section .owl-dots .owl-dot.active {
  position: relative;
  display: inline-block;
  margin: 30px 4px;
  padding: 3px;
  border: 1px solid #f7b100;
  border-radius: 50%;

}



.offer-section .owl-dots .owl-dot span {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0px;
  border-radius: 50%;
  background: #ddd;

}

.offer-section .owl-dots .owl-dot.active span,
.offer-section .owl-dots .owl-dot:hover span {
  background: #f7b100;

}


/*** 

====================================================================
	Choose Section
====================================================================

 ***/

.choose-section {
  position: relative;
  padding: 65px 0px 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
}

.choose-section:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  background-color: #f7f7f7;
}

.services-block-two {
  position: relative;
  margin-bottom: 30px;
}

.services-block-two .inner-box {
  position: relative;
  padding: 35px 28px 25px;
  background-color: #ffffff;
  border: 1px solid #ececec;
}

.services-block-two .inner-box .icon-box {
  position: relative;
  color: #232527;
  font-size: 64px;
  line-height: 1em;
  margin-bottom: 20px;
}

.services-block-two .inner-box h3 {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2em;
  margin-bottom: 5px;
}

.services-block-two .inner-box h3 a {
  color: #232527;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.services-block-two .inner-box h3 a:hover {
  color: #fd6502;
}

.services-block-two .inner-box .sub-title {
  position: relative;
  color: #fd6502;
  font-size: 14px;
  margin-bottom: 15px;
}

.services-block-two .inner-box .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 1.9em;
  margin-bottom: 20px;
}

.services-block-two .inner-box ul {
  position: relative;
}

.services-block-two .inner-box ul li {
  position: relative;
  margin-bottom: 6px;
  color: #848484;
  font-size: 14px;
  padding-left: 22px;
}

.services-block-two .inner-box ul li:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 9px;
  width: 6px;
  height: 6px;
  background-color: #eaeaea;
}

/*** 

====================================================================
	Testimonial Section
====================================================================

 ***/

.testimonial-section {
  position: relative;
  padding: 65px 0px 65px;
}

.testimonial-section .owl-carousel .owl-stage-outer {
  padding-top: 30px;
}

.testimonial-block {
  position: relative;
}

.testimonial-block .inner-box {
  position: relative;
  margin-left: 25px;
  padding: 48px 25px 15px;
  border: 1px solid #f2f2f2;
}

.testimonial-block .inner-box .image {
  position: absolute;
  left: -20px;
  top: -35px;
  width: 70px;
  -webkit-box-shadow: 0px 5px 13px 0px rgba(183, 183, 183, 1);
  -moz-box-shadow: 0px 5px 13px 0px rgba(183, 183, 183, 1);
  box-shadow: 0px 5px 13px 0px rgba(183, 183, 183, 1);
}

.testimonial-block .inner-box .quote-icon {
  position: absolute;
  left: 70px;
  top: -20px;
  width: 40px;
  height: 40px;
  color: #eeeeee;
  text-align: center;
  border-radius: 50%;
  line-height: 40px;
  font-size: 24px;
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
}

.testimonial-block .inner-box .text {
  position: relative;
  color: #848484;
  font-size: 18px;
  font-style: italic;
  line-height: 1.7em;
  margin-top: 5px;
}

.testimonial-block .inner-box .lower-box {
  position: relative;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #f2f2f2;
}

.testimonial-block .inner-box .lower-box h3 {
  position: relative;
  color: #232527;
  font-size: 18px;
  margin-bottom: 2px;
  font-weight: 600;
}

.testimonial-block .inner-box .lower-box .location {
  position: relative;
  color: #f7b100;
  font-size: 14px;
  margin-bottom: 10px;
}

.testimonial-block .inner-box .lower-box .rating {
  position: relative;
}

.testimonial-block .inner-box .lower-box .rating .fa {
  position: relative;
  color: #f7b100;
  margin-left: 0px;
  font-size: 12px;
}

.testimonial-section .owl-nav {
  display: none;
}

.testimonial-section .owl-dots {
  position: relative;
  text-align: center;
  margin-top: 40px;
}

.testimonial-section .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0px 6px;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.testimonial-section .owl-dots .owl-dot span {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #ededed;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.testimonial-section .owl-dots .owl-dot.active span,
.testimonial-section .owl-dots .owl-dot:hover span {
  background: #f7b100;
  border-color: #f7b100;
}

/*** 

====================================================================
	Client Section
====================================================================

 ***/

.client-section {
  position: relative;
  padding: 60px 0px 35px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.client-section:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(35, 38, 40, 0.90);
}

.client-section .title-column {
  position: relative;
  margin-bottom: 25px;
}

.client-section .title-column .sec-title {
  position: relative;
  margin-bottom: 30px;
}

.client-section .title-column .style-text {
  position: relative;
  color: #b0b0b0;
  font-size: 18px;
  line-height: 1.8em;
  font-style: italic;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

.client-section .title-column .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 1.9em;
  margin-bottom: 25px;
}

.client-section .title-column .partners {
  position: relative;
  color: #f7b100;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.client-section .title-column .partners .arrow {
  margin-left: 5px;
}

.client-section .clients-box {
  position: relative;
}

.client-section .clients-box .client-box {
  position: relative;
  padding: 0px;
  border-left: 1px solid rgba(255, 255, 255, 0.08);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.client-section .clients-box .client-box .image {
  position: relative;
  text-align: center;
  padding: 10px 0px;
}

.client-section .clients-box .client-box .image img {
  max-width: 100%;
  display: inline-block;
}

.client-section .clients-box .client-box:nth-child(1),
.client-section .clients-box .client-box:nth-child(5) {
  border-left: 1px solid transparent !important;
}

.client-section .clients-box .client-box:nth-child(5),
.client-section .clients-box .client-box:nth-child(6),
.client-section .clients-box .client-box:nth-child(7),
.client-section .clients-box .client-box:nth-child(8) {
  border-bottom: 0px;
}

.client-section.style-two {
  background-color: #f7f7f7;
}

.client-section.style-two:before {
  display: none;
}

.client-section.style-two .title-column .style-text {
  color: #969696;
}

.client-section.style-two .clients-box .client-box {
  border-bottom-color: #ececec;
  border-left-color: #ececec;
}

/*** 

====================================================================
	News Section
====================================================================

 ***/

.news-section {
  position: relative;
  padding: 65px 0px 40px;
}

.news-block {
  position: relative;
  margin-bottom: 40px;
}

.news-block .inner-box {
  position: relative;
}

.news-block .inner-box .image {
  position: relative;
}

.news-block .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
}

.news-block .inner-box .image .overlay-link {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  text-align: center;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.70);
}

.news-block .inner-box .image .overlay-link .icon {
  position: relative;
  font-size: 30px;
  top: 50%;
  color: #ffffff;
  margin-top: -20px;
}

.news-block .inner-box:hover .image .overlay-link {
  opacity: 1;
}

.news-block .inner-box .lower-box {
  position: relative;
  padding-bottom: 25px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  border-bottom: 1px solid #f2f2f2;
}

.news-block .inner-box:hover .lower-box {
  border-color: #fd6502;
}

.news-block .inner-box .lower-box .post-date {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 60px;
  height: 60px;
  color: #232527;
  text-align: center;
  padding-top: 7px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5em;
  background-color: #f7f7f7;
  font-family: 'Montserrat', sans-serif;
}

.news-block .inner-box .lower-box .content {
  position: relative;
  margin-left: 60px;
  padding: 20px 20px 0px;
  margin-bottom: -20px;
  top: -20px;
  z-index: 1;
  background-color: #ffffff;
}

.news-block .inner-box .lower-box .content .author {
  position: relative;
  color: #848484;
  font-size: 14px;
  padding-left: 20px;
  margin-bottom: 10px;
}

.news-block .inner-box .lower-box .content .author:before {
  position: absolute;
  content: '\f007';
  left: 0px;
  top: 0px;
  color: #fd6502;
  font-size: 16px;
  font-family: 'FontAwesome';
}

.news-block .inner-box .lower-box .content h3 {
  position: relative;
  color: #232527;
  font-size: 16px;
  line-height: 1.7em;
  font-weight: 600;
}

.news-block .inner-box .lower-box .content h3 a {
  color: #232527;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block .inner-box .lower-box .content h3 a:hover {
  color: #fd6502;
}

.news-block .inner-box .lower-box .content .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 1.8em;
  margin-top: 12px;
}

/*** 

====================================================================
	Main Footer Section
====================================================================

 ***/

.main-footer {
  position: relative;
  /* background-color:#232628;
	background-repeat:no-repeat;
	background-position:center center; */
}

.main-footer:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  /* background-color: #000000 !important; */
}

.main-footer .widgets-section {
  position: relative;
  padding: 15px 0px 15px;
  background-color: #000;
}

.main-footer .footer-widget {
  position: relative;
  margin-bottom: 30px;
}

.main-footer .footer-widget h2 {
  position: relative;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 25px;
  text-transform: capitalize;
}

.main-footer .footer-widget .text {
  position: relative;
  margin-bottom: 28px;
}

.main-footer .footer-widget .text p {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 1.8em;
  margin-bottom: 14px;
}

.main-footer .links-widget .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  margin-top: -5px;
  line-height: 1.8em;
}

/*Footer Links*/

.footer-links {
  position: relative;
}

.footer-links li {
  position: relative;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.footer-links li a {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.footer-links li a:before {
  position: absolute;
  content: '\f101';
  left: 0px;
  top: 0px;
  opacity: 0;
  line-height: 20px;
  font-family: 'FontAwesome';
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.footer-links li a:hover {
  padding-left: 15px;
}

.footer-links li a:hover::before {
  opacity: 1;
  color: #f7b100;
}

.footer-links li a:hover {
  color: #f7b100;
}

/*Offer Block*/

.offer-block {
  position: relative;
}

.offer-block .inner {
  position: relative;
  padding: 35px 30px;
  outline: 1px solid #2e3133;
  outline-offset: -12px;
  border: 1px solid #2e3133;
  background-color: #26292b;
}

.offer-block .inner h3 {
  position: relative;
  color: #f7b100;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.offer-block .inner .price {
  position: relative;
  color: #ffffff;
  font-size: 36px;
  font-weight: 600;
}

.offer-block .inner .price sup {
  font-weight: 600;
  font-size: 20px;
  font-style: italic;
  margin-right: 3px;
  font-family: 'Open Sans', sans-serif;
}

.offer-block .inner .price span {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4em;
  font-style: italic;
  margin-right: 10px;
  display: inline-block;
}

.offer-widget .widget-content .owl-nav {
  display: none;
}

.offer-widget .widget-content .single-item-carousel .owl-dots {
  position: absolute;
  right: 0px;
  top: -50px;
}

.offer-widget .widget-content .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  margin: 0px 4px;
  border: 1px solid #4a4d4e;
  border-radius: 50%;
  display: inline-block;
}

.offer-widget .widget-content .owl-dots .owl-dot.active,
.offer-widget .widget-content .owl-dots .owl-dot:hover {
  background-color: #f7b100;
  border-color: #f7b100;
}

.main-footer .footer-bottom {
  position: relative;
  padding: 10px 0px;
}

.main-footer .footer-bottom .copyright {
  position: relative;
  float: right;
  font-weight: bold;
  color: black;
  margin-right: 30px;
  font-family: 'Read-Regular';
}

.main-footer .footer-bottom .footer-nav {
  position: relative;
  text-align: left;
}

.main-footer .footer-bottom .footer-nav li {
  position: relative;
  margin-left: 20px;
  display: inline-block;
}

.main-footer .footer-bottom .footer-nav li a {
  position: relative;
  color: #848484;
  /* font-size:1.3rem; */
}

.main-footer .footer-bottom .footer-nav li a:hover {
  color: #ffce33;
}

/*** 

====================================================================
	Page Title Style
====================================================================

***/

.page-title {
  position: relative;
  text-align: center;
  padding: 100px 0px 100px 0px;
  /* padding: 50px 0px 250px 0px; */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  max-width: 1170px;
  margin: 0 auto;
}

.page-title:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(31, 37, 49, 0.73);
}

.page-subtitle {
  position: relative;
  /* padding-top: 32px; */
  padding-top: 22px;
  margin-top: 22px;
  font-family: 'Read-Light' !important;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  /* opacity: .8; */
}

.page-title h1 {
  position: relative;
  font-size: 36px;
  letter-spacing: 1px;
  line-height: 1.4em;
  font-family: 'RenaultLife-Regular' !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.page-title-lower {
  position: relative;
  text-align: center;
  padding: 70px 0px 35px 0px;
  background-color: #f3f5f6;
  max-width: 1169px;
  margin: 0 auto;
  margin-top: -60px;
}

.page-title-lower h1 {
  position: relative;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #000000;
  line-height: 1em;
  margin-top: 30px;
}

.page-title-lower h1:before {
  position: absolute;
  content: '';
  margin-top: 30px;
  left: 47%;
  width: 60px;
  height: 0.148rem;
  background-color: #000000;
}

.page-info {
  position: relative;
  color: #272727;
  border-bottom: 1px solid #eeeeee;
}

/* .page-title h1:before {
  position: absolute;
  content: '';
  margin-top: 57.5px;
  left: 47.8%;
  width: 50px;
  height: 3px;
   background-color: #ffce33; 
}*/

*/ .page-info h2 {
  font-size: 16px;
  color: #272727;
  line-height: 24px;
  font-weight: 700;
}

.page-info .bread-crumb {
  position: relative;
  font-size: 14px;
  padding: 18px 0px 17px;
}

.page-info .bread-crumb li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  margin-right: 24px;
  color: #848484;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
  font-family: 'Montserrat', sans-serif;
}

.page-info .bread-crumb li.current {
  color: #f7b100;
}

.page-info .bread-crumb li:last-child {
  margin-right: 0px;
}

.page-info .bread-crumb li:after {
  content: '\f0da';
  font-family: 'FontAwesome';
  position: absolute;
  right: -21px;
  width: 10px;
  line-height: 24px;
  font-size: 16px;
  color: #848484;
}

.page-info .bread-crumb li:last-child:after {
  display: none;
}

.page-info .bread-crumb li a {
  color: #848484;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.page-info .bread-crumb li a:hover {
  color: #ff6600;
}

/*** 

====================================================================
	About Section
====================================================================

***/

.about-section {
  position: relative;
  padding: 65px 0px 30px;
}

.about-section .content-column {
  position: relative;
  margin-bottom: 40px;
}

.about-section .content-column .content-column {
  position: relative;
}

.about-section .content-column .inner-column {
  position: relative;
}

.about-section .content-column .inner-column {
  position: relative;
}

.about-section .content-column .inner-column .bold-text {
  position: relative;
  font-size: 20px;
  color: #232527;
  font-weight: 600;
  line-height: 1.6em;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
}

.about-section .content-column .inner-column .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 1.8em;
  margin-bottom: 30px;
}

.about-section .content-column .inner-column h3 {
  position: relative;
  color: #232527;
  font-size: 18px;
  font-weight: 600;
}

.about-section .content-column .inner-column .designation {
  position: relative;
  color: #f7b100;
  font-size: 14px;
}

.about-section .blocks-column {
  position: relative;
}

.about-section .blocks-column {
  position: relative;
}

.about-block {
  position: relative;
  margin-bottom: 30px;
}

.about-block .inner-box {
  position: relative;
}

.about-block .inner-box .image {
  position: relative;
}

.about-block .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
}

.about-block .inner-box .lower-box {
  position: relative;
  padding-top: 24px;
}

.about-block .inner-box .lower-box h3 {
  position: relative;
  font-weight: 600;
  font-size: 20px;
  color: #232527;
  margin-bottom: 6px;
}

.about-block .inner-box .lower-box h3 a {
  color: #232527;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.about-block .inner-box .lower-box h3 a:hover {
  color: #f7b100;
}

.about-block .inner-box .lower-box .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 1.8em;
}

/*** 

====================================================================
	Advantage Section
====================================================================

***/

.advantage-section {
  position: relative;
  padding: 65px 0px 40px;
  background-color: #232628;
}

.services-block-three {
  position: relative;
  margin-bottom: 32px;
}

.services-block-three .inner-box {
  position: relative;
  padding-left: 85px;
}

.services-block-three .inner-box .icon-box {
  position: absolute;
  left: 0px;
  top: 25px;
  font-size: 60px;
  line-height: 1em;
}

.services-block-three .inner-box h3 {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 1px;
}

.services-block-three .inner-box h3 a {
  color: #ffffff;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.services-block-three .inner-box h3 a:hover {
  color: #f7b100;
}

.services-block-three .inner-box .sub-title {
  position: relative;
  color: #fd6502;
  font-size: 14px;
  margin-bottom: 12px;
  font-family: 'Open Sans', sans-serif;
}

.services-block-three .inner-box .text {
  position: relative;
  color: #848484;
  font-size: 14px;
}

.advantage-section .customer-column {
  position: relative;
}

.advantage-section .customer-column .inner-box {
  position: relative;
  padding: 30px 28px;
  border: 1px solid #2f3335;
}

.advantage-section .customer-column .inner-box .upper-box {
  position: relative;
  padding-left: 52px;
  padding-bottom: 22px;
  margin-bottom: 27px;
  border-bottom: 1px solid #2f3335;
}

.advantage-section .customer-column .inner-box .upper-box .icon {
  position: absolute;
  left: 0px;
  top: 5px;
  color: #f7b100;
  font-size: 40px;
  line-height: 1em;
}

.advantage-section .customer-column .inner-box .upper-box h3 {
  position: relative;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 3px;
}

.advantage-section .customer-column .inner-box .upper-box .title {
  position: relative;
  color: #848484;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
}

.advantage-section .customer-column .inner-box .lower-box {
  position: relative;
}

.advantage-section .customer-column .inner-box .lower-box .number {
  position: relative;
  color: #f7b100;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 18px;
  font-family: 'Montserrat', sans-serif;
}

.advantage-section .customer-column .inner-box .lower-box .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 1.8em;
  margin-bottom: 20px;
}

.advantage-section .customer-column .inner-box .lower-box h4 {
  position: relative;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.advantage-section .customer-column .inner-box .lower-box ul {
  position: relative;
}

.advantage-section .customer-column .inner-box .lower-box ul li {
  position: relative;
  color: #848484;
  font-size: 16px;
  margin-bottom: 3px;
}

.advantage-section .customer-column .inner-box .lower-box ul li span {
  font-weight: 600;
}

/*** 

====================================================================
	Team Section
====================================================================

***/

.team-section {
  position: relative;
  padding: 65px 0px 70px;
}

.team-block {
  position: relative;
}

.team-block .inner-box {
  position: relative;
}

.team-block .inner-box .image-column {
  position: relative;
  padding: 0px;
}

.team-block .inner-box .image-column .image {
  position: relative;
}

.team-block .inner-box .image-column .image .overlay-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  text-align: center;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.80);
}

.team-block .inner-box .image-column .image .overlay-box .social-icon-one {
  top: 50%;
  margin-top: -20px;
}

.team-block .inner-box:hover .image-column .image .overlay-box {
  opacity: 1;
}

.team-block .inner-box .image-column .image img {
  position: relative;
  width: 100%;
  display: block;
}

.team-block .inner-box .content-column {
  position: relative;
  padding: 0px;
  border: 1px solid #f2f2f2;
}

.team-block .inner-box .content-column .content-inner {
  position: relative;
  padding: 25px 25px 0px;
}

.team-block .inner-box .content-column .content-inner h3 {
  position: relative;
  font-weight: 600;
  font-size: 20px;
}

.team-block .inner-box .content-column .content-inner h3 a {
  color: #232527;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.team-block .inner-box .content-column .content-inner h3 a:hover {
  color: #f7b100;
}

.team-block .inner-box .content-column .content-inner .sub-title {
  position: relative;
  color: #f7b100;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f2f2f2;
  font-family: 'Open Sans', sans-serif;
}

.team-block .inner-box .content-column .content-inner .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 1.8em;
  margin-bottom: 15px;
}

.team-section .owl-nav {
  display: none;
}

.team-section .owl-dots {
  position: relative;
  text-align: center;
  margin-top: 40px;
}

.team-section .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0px 4px;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.team-section .owl-dots .owl-dot span {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0px;
  border-radius: 50%;
  background: none;
  border: 1px solid #ededed;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.team-section .owl-dots .owl-dot.active span,
.team-section .owl-dots .owl-dot:hover span {
  background: #f7b100;
  border-radius: 50%;
  border-color: #f7b100;
}

/*** 

====================================================================
	Faq Section
====================================================================

***/

.faq-section {
  position: relative;
  padding: 65px 0px 50px;
}

.faq-section .faq-column {
  position: relative;
}

.faq-section .faq-column .title-text {
  position: relative;
  color: #848484;
  font-size: 14px;
  margin-bottom: 30px;
}

.faq-section .faq-column .sec-title {
  position: relative;
  margin-bottom: 30px;
}

/*** 

====================================================================
	Faq Tabs Style
====================================================================

***/

.faq-info-tabs .prod-tabs {
  position: relative;
  margin-bottom: 0px;
}

.faq-info-tabs .prod-tabs .tab-btns {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.faq-info-tabs .prod-tabs .tab-btns .tab-btn {
  position: relative;
  top: 1px;
  display: block;
  float: left;
  margin-right: 12px;
  font-size: 16px;
  background: #f7f7f7;
  color: #232527;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 14px 20px 12px;
  transition: all 500ms ease;
  font-family: 'Montserrat', sans-serif;
}

.faq-info-tabs .prod-tabs .tab-btns .tab-btn:hover,
.faq-info-tabs .prod-tabs .tab-btns .tab-btn.active-btn {
  color: #000000;
  background: #f7b100;
}

.faq-info-tabs .prod-tabs .tabs-content {
  position: relative;
}

.faq-info-tabs .prod-tabs .tabs-content .tab {
  position: relative;
  padding: 0px 0px;
  display: none;
}

.faq-info-tabs .prod-tabs .tabs-content .tab.active-tab {
  display: block;
}

/*** 

====================================================================
	Accordion Style
====================================================================

***/

.accordion-box {
  position: relative;
  z-index: 10;
  margin-bottom: 30px;
}

.accordion-box .block {
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #f2f2f2;
}

.accordion-box .block:last-child {
  margin-bottom: 0px;
}

.accordion-box .block:last-child .acc-btn {
  border: 0px;
}

.accordion-box .block .acc-btn {
  position: relative;
  font-size: 18px;
  margin-bottom: 0px;
  cursor: pointer;
  background: none;
  line-height: 24px;
  font-weight: 600;
  overflow: hidden;
  color: #232527;
  padding: 28px 20px 28px 75px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  font-family: 'Montserrat', sans-serif;
}

.accordion-box .block .acc-btn .icon-outer {
  position: absolute;
  left: 20px;
  top: 50%;
  font-size: 12px;
  color: #f7b100;
  line-height: 26px;
  text-align: center;
  border-radius: 50%;
  font-weight: 600;
  margin-top: -19px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.accordion-box .block .acc-btn .icon-outer .icon {
  position: relative;
  top: 50%;
  display: block;
  width: 35px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #f2f2f2;
}

.accordion-box .block .acc-btn.active .icon-outer .icon-plus {
  opacity: 0;
}

.accordion-box .block .icon-outer .icon-minus {
  opacity: 0;
  font-weight: 700;
  margin-top: -11px;
}

.accordion-box .block.active-block .icon-minus {
  opacity: 1;
  color: #ffffff;
  margin-top: -40px;
  border-color: #f7b100 !important;
  background-color: #f7b100;
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
  background-color: #ffffff;
}

.accordion-box .block .acc-content.current {
  display: block;
}

.accordion-box .block .content {
  position: relative;
  font-size: 15px;
  padding: 0px 15px 25px 70px;
}

.accordion-box .block .content p {
  margin-bottom: 10px;
  line-height: 1.8em;
  font-size: 14px;
  color: #848484;
  font-family: 'Open Sans', sans-serif;
}

.accordion-box .block .content p:last-child {
  margin-bottom: 0px;
}

/*Faq Search Box*/

.faq-search-box {
  position: relative;
  margin-bottom: 50px;
}

.faq-search-box .outer-box {
  position: relative;
  padding: 28px 20px;
  background-color: #f7f7f7;
}

.invent-style-two .faq-search-box .outer-box {
  background-color: #232628;
}

.faq-search-box .outer-box .form-group {
  position: relative;
  margin: 0px;
}

.faq-search-box .outer-box .form-group input[type="text"],
.faq-search-box .outer-box .form-group input[type="search"] {
  position: relative;
  line-height: 28px;
  padding: 10px 50px 10px 20px;
  background: #e9e9e9;
  display: block;
  font-size: 14px;
  width: 100%;
  height: 45px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.invent-style-two .faq-search-box .outer-box .form-group input[type="text"],
.invent-style-two .faq-search-box .outer-box .form-group input[type="search"] {
  background: #2f3335;
  color: #ffffff;
}

.faq-search-box .outer-box .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 45px;
  width: 50px;
  display: block;
  font-size: 16px;
  color: #919191;
  line-height: 100%;
  background: none;
  font-weight: normal;
}

/*** 

====================================================================
	Faq Cars Select Form
====================================================================

***/

.select-cars-tabs {
  position: relative;
  margin-top: 13px;
  /* background-color: #ddd;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0.07); */

}

.invent-style-two .select-cars-tabs {
  background-color: #232628;
}

.select-cars-tabs .prod-tabs {
  position: relative;
  margin-bottom: 50px;
}

.select-cars-tabs .prod-tabs .tab-btns {
  position: relative;
  z-index: 1;
  margin-bottom: 22px;
}

.select-cars-tabs .prod-tabs .tab-btns .tab-btn {
  position: relative;
  top: 1px;
  display: block;
  float: left;
  width: 49.6%;
  border-right: 1px solid #ffffff;
  margin-right: 2px;
  font-size: 16px;
  background: #e8e8e8;
  color: #232527;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  padding: 14px 20px 12px;
  transition: all 500ms ease;
  font-family: 'Montserrat', sans-serif;
}

.invent-style-two .select-cars-tabs .prod-tabs .tab-btns .tab-btn {
  background: #2f3335;
  border-right-color: #2f3335;
  color: #ffffff;
}

.select-cars-tabs .prod-tabs .tab-btns .tab-btn:last-child {
  border-right: 0px;
  margin-right: 0px;
}

.select-cars-tabs .prod-tabs .tab-btns .tab-btn:hover,
.select-cars-tabs .prod-tabs .tab-btns .tab-btn.active-btn {
  color: #f7b100;
  background-color: #f7f7f7;
}

.invent-style-two .select-cars-tabs .prod-tabs .tab-btns .tab-btn:hover,
.invent-style-two .select-cars-tabs .prod-tabs .tab-btns .tab-btn.active-btn {
  background-color: #232628;
}

.select-cars-tabs .prod-tabs .tabs-content {
  position: relative;
  padding: 0px 18px;
  padding-bottom: 10px;
}

.select-cars-tabs .prod-tabs .tabs-content .tab {
  position: relative;
  display: none;
}

.select-cars-tabs .prod-tabs .tabs-content .tab.active-tab {
  display: block;
}

/*Default Form*/
/* 
.cars-form .form-group{
	
	margin-bottom: 10px;
	margin-top:10px;
    /* border-bottom: 1px dotted #0000001a; 

.cars-form .form-group:nth-of-type(3) {
	padding-bottom: 20px;
	margin: 10px;
}
.cars-form .form-group:nth-of-type(6) {
	padding-bottom: 20px;
}
.cars-form .row{
	margin:0px -5px;
}

.cars-form .form-group.inner-group{
	padding:0px 5px;
} */


.cars-form .form-group label {
  position: relative;
  color: #232527;
  font-size: 14px;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
}

.invent-style-two .cars-form .form-group label {
  color: #ffffff;
}

.cars-form .form-group .ui-selectmenu-button.ui-button {
  background: #e9e9e9;
}

.invent-style-two .cars-form .form-group .ui-selectmenu-button.ui-button {
  background: #2f3335;
  border-color: #2f3335;
  color: #909090;
}

.invent-style-two .cars-form .form-group .ui-button .ui-icon:before {
  color: #909090;
}

.cars-form .form-group button.btn-style-one {
  width: 100%;
  padding: 9px 25px;
  margin-top: 10px;
}

.cars-form .form-group .reset-all {
  position: relative;
  color: #f7b100;
  font-size: 12px;
  font-weight: 400;
  background: none;
  font-family: 'Montserrat', sans-serif;
  text-decoration: underline;
}

.cars-form .form-group .reset-all .fa {
  padding-right: 10px;
}

.cars-form input[type="text"],
.cars-form input[type="email"],
.cars-form input[type="password"],
.cars-form select,
.cars-form textarea {
  display: block;
  width: 100%;
  /* height:50px; */
  font-size: 16px;
  color: #848484;
  line-height: 26px;
  /* padding:12px 15px; */
  font-weight: 400;
  /* background:#e9e9e9; */
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.cars-form input[type="text"] {
  font-family: 'Read-Regular' !important;
  border-radius: 4px !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #777777;

}


.cars-form input:focus,
.cars-form select:focus,
.cars-form textarea:focus {
  border-color: #0a0909;
}

/*** 

====================================================================
	Loan Section
====================================================================

***/

.loan-section {
  position: relative;
  padding: 65px 0px 65px;
}

.loan-section .sec-title {
  margin-bottom: 30px;
}

.loan-section .inner-column {
  position: relative;
}

.loan-section .inner-column .text {
  position: relative;
  color: #999999;
  font-size: 14px;
  line-height: 2em;
  margin-bottom: 25px;
}

/*** 

====================================================================
	Calculate Section
====================================================================

***/

.calculate-form {
  position: relative;
}

.calculate-form .form-box {
  position: relative;
  padding: 35px 38px 20px;
  background-color: #f7f7f7;
}

.calculate-form .form-box:after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: -20px;
  z-index: 1;
  margin-left: -8px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #f7f7f7;
}

.calculate-form .form-group {
  position: relative;
  margin-bottom: 20px;
  min-height: 74px;
}

.calculate-form .form-group label {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #848484;
  margin-bottom: 4px;
  font-family: 'Open Sans', sans-serif;
}

.calculate-form .form-group input[type="text"],
.calculate-form .form-group input[type="email"],
.calculate-form .form-group input[type="password"],
.calculate-form .form-group select,
.calculate-form .form-group textarea {
  display: block;
  width: 100%;
  height: 45px;
  font-size: 14px;
  color: #848484;
  line-height: 26px;
  padding: 10px 18px;
  font-weight: 400;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.calculate-form .form-group input:focus,
.calculate-form .form-group select:focus,
.calculate-form .form-group textarea:focus {
  border-color: #ff6600;
}

.calculate-form .form-group button {
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  margin-top: 10px;
  padding: 11px 35px;
}

.calculate-form .lower-box {
  position: relative;
  padding: 45px 15px 20px;
  text-align: center;
  border: 1px solid #f7f7f7;
  background-color: #ffffff;
}

.calculate-form .lower-box h3 {
  position: relative;
  color: #232527;
  font-size: 24px;
  font-weight: 700;
}

.calculate-form .lower-box .amount {
  position: relative;
  color: #848484;
  font-size: 14px;
  margin-top: 6px;
}

.calculate-form .lower-box .plus-box {
  position: relative;
}

.calculate-form .lower-box .plus-box .icon {
  position: relative;
  width: 28px;
  height: 28px;
  color: #dedede;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
  line-height: 27px;
  display: inline-block;
  border: 1px solid #dedede;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.calculate-form .lower-box .plus-box:hover .icon {
  color: #f7b100;
  border-color: #f7b100;
}

.calculate-form .lower-box .plus-box .text {
  position: relative;
  color: #e6e6e6;
  font-size: 24px;
  font-weight: 400;
  margin-left: 60px;
  display: inline-block;
  font-family: 'Pacifico', cursive;
}

.calculate-form .lower-box .plus-box .text:after {
  position: absolute;
  content: '';
  right: 100%;
  bottom: 0px;
  width: 54px;
  height: 23px;
  background: url(../images/resource/arrow.png) no-repeat;
}

/*** 

====================================================================
	Cell Car Section
====================================================================

***/

.sell-car-section {
  position: relative;
  padding: 65px 0px 30px;
}

.sell-car-section .cell-car-column .sec-title {
  margin-bottom: 28px;
}

.sell-car-section .cell-car-column .inner-column {
  position: relative;
}

.sell-car-section .cell-car-column .inner-column .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 1.8em;
  margin-bottom: 28px;
}

/*Cell Car Form*/

.sell-car-form {
  position: relative;
  margin-bottom: 50px;
}

.sell-car-form h2 {
  position: relative;
  font-size: 18px;
  color: #232527;
  font-weight: 600;
  padding: 16px 25px 12px;
  background-color: #f7f7f7;
  border: 1px solid #f2f2f2;
  border-bottom: 0px;
}

.sell-car-form .form-box {
  position: relative;
  border: 1px solid #f2f2f2;
}

.sell-car-form .form-box {
  position: relative;
  padding: 25px 30px 15px;
}

.sell-car-form .form-box .form-group {
  margin-bottom: 25px;
}

.sell-car-form .form-box .form-group label {
  position: relative;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  font-family: 'Open Sans', sans-serif;
}

.sell-car-form .form-box .form-group input[type="text"],
.sell-car-form .form-box .form-group input[type="email"],
.sell-car-form .form-box .form-group input[type="password"],
.sell-car-form .form-box .form-group select,
.sell-car-form .form-box .form-group textarea {
  display: block;
  width: 100%;
  height: 48px;
  font-size: 14px;
  color: #848484;
  line-height: 26px;
  padding: 10px 18px;
  font-weight: 400;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sell-car-form .form-box .form-group textarea {
  width: 100%;
  resize: none;
  height: 100px;
}

.sell-car-form .form-box .form-group input:focus,
.sell-car-form .form-box .form-group select:focus,
.sell-car-form .form-box .form-group textarea:focus {
  border-color: #ff6600;
}

.sell-car-form .form-box .form-group label.upload {
  color: #f7b100;
}

.sell-car-form .form-box .form-group .upload-btn {
  position: relative;
  padding: 10px 20px;
  line-height: 28px;
  background: #f7f7f7;
  min-width: 200px;
  border-radius: 2px;
  text-align: left;
  margin-right: 20px;
}

.sell-car-form .form-box .form-group .upload-btn .fa {
  padding-right: 7px;
}

.sell-car-form .form-box .form-group .more-btn {
  position: relative;
  padding: 10px 0px;
  line-height: 28px;
  background: none;
  border-radius: 2px;
  text-align: left;
}

.sell-car-form .form-box .form-group .theme-btn {
  margin-top: 15px;
}

/*** 

====================================================================
	Cars Compare Section
====================================================================

***/

.cars-compare-section {
  position: relative;
  padding: 65px 0px 30px;
}

.cars-compare-section .title-column {
  position: relative;
  margin-bottom: 40px;
}

.cars-compare-section .title-column h2 {
  position: relative;
  font-weight: 700;
  color: #232527;
  font-size: 24px;
  padding-bottom: 15px;
  margin-bottom: 34px;
  text-transform: uppercase;
}

.cars-compare-section .title-column h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 55px;
  height: 2px;
  background-color: #f7b100;
}

.cars-compare-section .title-column .select-form {
  position: relative;
}

.cars-compare-section .title-column .add-block {
  position: relative;
  margin-top: 25px;
}

.cars-compare-section .title-column .add-block .inner {
  position: relative;
  background-size: cover;
}

.cars-compare-section .title-column .add-block .inner .image {
  position: relative;
}

.cars-compare-section .title-column .add-block .inner .image img {
  position: relative;
  width: 100%;
  display: block;
}

.cars-compare-section .title-column .add-block .inner .image .overlay-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 30px 30px;
}

.cars-compare-section .title-column .add-block .inner .image .overlay-box h3 {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 1em;
  margin-bottom: 10px;
}

.cars-compare-section .title-column .add-block .inner .image .overlay-box .text {
  position: relative;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 15px;
}

.cars-compare-section .title-column .add-block .inner .image .overlay-box .more-btn {
  position: relative;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 15px;
  text-transform: uppercase;
  background-color: #f7b100;
  font-family: 'Montserrat', sans-serif;
}

.cars-compare-section .select-form .form-group {
  position: relative;
  margin-bottom: 10px;
}

.cars-compare-section .select-form .form-group .select-box {
  position: relative;
}

.cars-compare-section .select-form .form-group .select-box label {
  position: relative;
  font-size: 14px;
  color: #232527;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 0px;
  padding-left: 32px;
  line-height: 1.3em;
  font-family: 'Open Sans', sans-serif;
}

.cars-compare-section .select-form .form-group .select-box input[type="checkbox"] {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 16px;
  height: 16px;
  margin: 0px;
  visibility: hidden;
  background-color: #ffffff;
}

.cars-compare-section .select-form .form-group .select-box .default-check {
  position: absolute;
  left: 0px;
  top: 1px;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 1px solid #cfcfcf;
}

.cars-compare-section .select-form .form-group .select-box .check-icon {
  position: absolute;
  content: '\f00c';
  left: 0px;
  top: 0px;
  width: 16px;
  height: 16px;
  color: #f7b100;
  z-index: 99;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  opacity: 0;
  font-family: 'FontAwesome';
}

.cars-compare-section .select-form .form-group .select-box input[type="checkbox"]:checked+label .check-icon {
  opacity: 1;
}

.cars-compare-section .options-cars-column {
  position: relative;
  margin-bottom: 40px;
}

.cars-compare-section .options-cars-column .compare-btn {
  padding: 11px 22px;
}

.car-option-block {
  position: relative;
  margin-bottom: 20px;
}

.car-option-block .inner-box {
  position: relative;
}

.car-option-block .inner-box h2 {
  position: relative;
  color: #232527;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 15px 13px;
  background-color: #f7f7f7;
  font-family: 'Montserrat', sans-serif;
}

.car-option-block .inner-box .icon-box {
  position: relative;
  padding: 43px 0px;
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid #f7f7f7;
}

.car-option-block .inner-box .icon-box .icon {
  position: relative;
  color: #eaeaea;
  font-size: 106px;
  line-height: 1em;
}

.default-form {
  position: relative;
}

.default-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.default-form .form-group .ui-selectmenu-button.ui-button {
  border: 1px solid #f2f2f2;
}

/*** 

====================================================================
	Services Section Two
====================================================================

***/

.services-section-two {
  position: relative;
  padding: 25px 0px 20px;
}

.services-section-two .image-column {
  position: relative;
  margin-bottom: 30px;
}

.services-section-two .image-column .image {
  position: relative;
}

.services-section-two .image-column .image img {
  position: relative;
  width: 100%;
  display: block;
}

.services-section-two .content-column {
  position: relative;
}

.services-section-two .content-column .inner-column {
  position: relative;
}

.services-section-two .content-column .inner-column .sec-title {
  position: relative;
  margin-bottom: 25px;
}

.services-section-two .content-column .inner-column .text {
  font-family: 'RenaultLife-Regular' !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000000;
  padding-bottom: 20px;
}

.services-blocks-section {
  position: relative;
  padding-bottom: 20px;
}

.services-blocks-section {
  position: relative;
  margin-top: 30px;
  padding-bottom: 1px;
  border-bottom: 1px solid #f2f2f2;
}

.services-block-four {
  position: relative;
  margin-bottom: 30px;
}

.services-block-four .inner-box {
  position: relative;
  padding-left: 120px;
  min-height: 100px;
}

.services-block-four .inner-box .image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 97px;
  height: 97px;
  border-radius: 50%;
  overflow: hidden;
  padding: 8px;
  border: 1px solid #f2f2f2;
}

.services-block-four .inner-box .image img {
  border-radius: 50%;
}

.services-block-four .inner-box h3 {
  position: relative;
  color: #232527;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.services-block-four .inner-box h3 a {
  color: #232527;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.services-block-four .inner-box h3 a:hover {
  color: #f7b100;
}

.services-block-four .inner-box .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 1.8em;
}

/*** 

====================================================================
	Services Section three
====================================================================

***/

.services-section-three {
  position: relative;
  padding: 65px 0px 70px;
  background-color: #232628;
}

.services-block-five {
  position: relative;
}

.services-block-five .inner-box {
  position: relative;
  text-align: center;
  padding: 40px 10px 45px;
  background-color: #26292b;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.services-block-five .inner-box .content-box {
  position: relative;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.services-block-five .inner-box .content-box .icon-box {
  position: relative;
  color: #f7b100;
  font-size: 60px;
  line-height: 1em;
  margin-bottom: 10px;
}

.services-block-five .inner-box .content-box h3 {
  position: relative;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 12px;
}

.services-block-five .inner-box .content-box h3 a {
  color: #ffffff;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.services-block-five .inner-box .content-box h3 a:hover {
  color: #f7b100;
}

.services-block-five .inner-box .content-box .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 1.9em;
}

.services-block-five .inner-box .overlay-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  display: block;
  opacity: 0;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.services-block-five .inner-box .overlay-box:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(255, 84, 0, 0.80);
}

.services-block-five .inner-box:hover .content-box {
  opacity: 0;
}

.services-block-five .inner-box:hover .overlay-box {
  opacity: 1;
}

.services-block-five .inner-box .overlay-box .overlay-inner {
  position: relative;
  padding: 30px 10px;
}

.services-block-five .inner-box .overlay-box .overlay-inner .icon-box {
  position: relative;
  color: #ffffff;
  font-size: 60px;
  line-height: 1em;
  margin-bottom: 12px;
}

.services-block-five .inner-box .overlay-box .overlay-inner h3 {
  position: relative;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 12px;
}

.services-block-five .inner-box .overlay-box .overlay-inner h3 a {
  color: #ffffff;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.services-block-five .inner-box .overlay-box .overlay-inner .text {
  position: relative;
  color: #ffffff;
  margin-bottom: 12px;
}

.services-block-five .inner-box .overlay-box .overlay-inner .read-more {
  position: relative;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
}

.services-section-three .owl-nav {
  display: none;
}

.services-section-three .owl-dots {
  position: relative;
  text-align: center;
  margin-top: 40px;
}

.services-section-three .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0px 4px;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.services-section-three .owl-dots .owl-dot span {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0px;
  border-radius: 50%;
  background: #232628;
  border: 1px solid #4a4d4e;
  transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}

.services-section-three .owl-dots .owl-dot.active span,
.services-section-three .owl-dots .owl-dot:hover span {
  background: #f7b100;
  border-color: #f7b100;
}

/*** 

====================================================================
	Services schedule Section
====================================================================

***/

.services-schedule-sections {
  position: relative;
  padding: 65px 0px 40px;
}

/*Cell Car Form*/

.services-form {
  position: relative;
  margin-bottom: 50px;
}

.services-schedule-sections .services-form {
  margin-bottom: 10px;
}

.services-form .form-box {
  position: relative;
}

.services-form .form-box .form-group {
  margin-bottom: 25px;
}

.services-form .form-box .form-group .ui-selectmenu-button.ui-button {
  height: 48px;
  padding: 11px 18px;
  line-height: 25px;
}

.services-form .form-box .form-group label {
  position: relative;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  font-family: 'Open Sans', sans-serif;
}

.services-form .form-box .form-group .clock-icon {
  position: absolute;
  right: 30px;
  top: 41px;
  z-index: 10;
  color: #f7b100;
}

.services-form .form-box .form-group input[type="text"],
.services-form .form-box .form-group input[type="email"],
.services-form .form-box .form-group input[type="password"],
.services-form .form-box .form-group select,
.services-form .form-box .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 48px;
  font-size: 14px;
  color: #848484;
  line-height: 26px;
  padding: 10px 18px;
  font-weight: 400;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.services-form .form-box .form-group textarea {
  width: 100%;
  resize: none;
  height: 100px;
}

.services-form .form-box .form-group input:focus,
.services-form .form-box .form-group select:focus,
.services-form .form-box .form-group textarea:focus {
  border-color: #ff6600;
}

.services-form .form-box .form-group label.upload {
  color: #f7b100;
}

.services-form .form-box .form-group input[type="file"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
}

.services-form .form-box .form-group button {
  margin-top: 15px;
  width: 100%;
}

.services-form .form-box .form-group .text {
  position: relative;
  color: #232527;
  font-size: 14px;
  margin-top: 30px;
  font-family: 'Open Sans', sans-serif;
}

.services-schedule-sections .time-column {
  position: relative;
  margin-bottom: 30px;
}

.services-schedule-sections .time-column .inner-column {
  position: relative;
  padding: 22px 20px;
  margin-top: 30px;
  border: 1px solid #ececec;
  background-color: #ffffff;
}

.services-schedule-sections .time-column .inner-column h3 {
  position: relative;
  font-weight: 600;
  color: #232527;
  font-size: 18px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.services-schedule-sections .time-column .inner-column h3:before {
  position: absolute;
  content: '\f017';
  left: 0px;
  top: 0px;
  color: #f7b100;
  font-size: 14px;
  font-weight: 400;
  font-family: 'FontAwesome';
}

.services-schedule-sections .time-column .inner-column ul {
  position: relative;
}

.services-schedule-sections .time-column .inner-column ul li {
  position: relative;
  color: #848484;
  font-size: 14px;
  margin-bottom: 8px;
  background-color: #ffffff;
}

.services-schedule-sections .time-column .inner-column ul li:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 12px;
  width: 100%;
  height: 1px;
  background-color: #f2f2f2;
}

.services-schedule-sections .time-column .inner-column .left-text {
  position: relative;
  padding-right: 5px;
  background-color: #ffffff;
}

.services-schedule-sections .time-column .inner-column ul li .right-text {
  position: relative;
  float: right;
  width: 90px;
  z-index: 1;
  text-align: left;
  padding-left: 3px;
  background-color: #ffffff;
}

.services-schedule-sections .time-column .inner-column .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 1.9em;
}

/*** 

====================================================================
	Brands Section
====================================================================

***/

.brands-section {
  position: relative;
  padding: 65px 0px 70px;
}

.brands-section .inner-section {
  position: relative;
  border: 1px solid #f2f2f2;
}

.brands-section .inner-section .sponsor-block {
  position: relative;
  width: 20%;
  float: left;
  padding: 0px 0px;
  text-align: center;
  border-right: 1px solid #f2f2f2;
}

.brands-section .inner-section .sponsor-block:nth-child(5) {
  border: 0px;
}

.brands-section .inner-section .sponsor-block:nth-child(1),
.brands-section .inner-section .sponsor-block:nth-child(2),
.brands-section .inner-section .sponsor-block:nth-child(3),
.brands-section .inner-section .sponsor-block:nth-child(4),
.brands-section .inner-section .sponsor-block:nth-child(5) {
  border-bottom: 1px solid #f2f2f2;
}

/*** 

====================================================================
	News Page Section
====================================================================

***/

.news-page-section {
  position: relative;
  padding: 70px 0px 70px;
}

/*** 

====================================================================
	Styled Pagination
====================================================================

***/

.styled-pagination {
  position: relative;
}

.styled-pagination ul {
  position: relative;
  display: inline-block;
}

.styled-pagination li {
  position: relative;
  display: block;
  float: left;
  margin: 0px 8px 8px 0px;
}

.styled-pagination li a {
  position: relative;
  display: block;
  line-height: 48px;
  height: 52px;
  font-size: 18px;
  min-width: 52px;
  color: #232527;
  font-weight: 600;
  text-align: center;
  background: #ffffff;
  border: 2px solid #f2f2f2;
  text-transform: capitalize;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  font-family: 'Montserrat', sans-serif;
}

.styled-pagination li a:hover,
.styled-pagination li a.active {
  color: #ffffff;
  border-color: #f7b100 !important;
  background-color: #f7b100 !important;
}

/*** 

====================================================================
	Sidebar Page Container
====================================================================

***/

.sidebar-page-container {
  position: relative;
  overflow: hidden;
}

.sidebar-page-container .content-side {
  position: relative;
  margin-bottom: 40px;
  padding-top: 70px;
  padding-bottom: 30px;
}

.sidebar-page-container .sidebar-side {
  position: relative;
  padding-top: 70px;
}

.sidebar-page-container .sidebar.sidebar-padding {
  padding-left: 30px;
}

.sidebar-page-container .sidebar-side .sidebar {
  position: relative;
}

.sidebar-page-container .sidebar-side .with-border:before {
  position: absolute;
  content: '';
  left: 0px;
  top: -100px;
  height: 5000px;
  border-left: 1px solid #f2f2f2;
}

/*News Block Two*/

.news-block-two {
  position: relative;
  margin-bottom: 50px;
}

.news-block-two .inner-box {
  position: relative;
}

.news-block-two .inner-box .image {
  position: relative;
}

.news-block-two .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
}

.news-block-two .inner-box .image .overlay-link {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  text-align: center;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.70);
}

.news-block-two .inner-box .image .overlay-link .icon {
  position: relative;
  font-size: 30px;
  top: 50%;
  color: #ffffff;
  margin-top: -20px;
}

.news-block-two .inner-box:hover .image .overlay-link {
  opacity: 1;
}

.news-block-two .inner-box .lower-box {
  position: relative;
  padding-bottom: 25px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  border-bottom: 1px solid #f2f2f2;
}

.news-block-two .inner-box:hover .lower-box {
  border-color: #fd6502;
}

.news-block-two .inner-box .lower-box .post-date {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 60px;
  height: 60px;
  color: #232527;
  text-align: center;
  padding-top: 7px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5em;
  background-color: #f7f7f7;
  font-family: 'Montserrat', sans-serif;
}

.news-block-two .inner-box .lower-box .content {
  position: relative;
  margin-left: 60px;
  padding: 20px 20px 0px;
  margin-bottom: -20px;
  top: -20px;
  z-index: 1;
  background-color: #ffffff;
}

.news-block-two .inner-box .lower-box .content .post-meta {
  position: relative;
  margin-bottom: 5px;
}

.news-block-two .inner-box .lower-box .content .post-meta li {
  position: relative;
  margin-right: 14px;
  color: #848484;
  font-size: 14px;
  padding-left: 20px;
  display: inline-block;
}

.news-block-two .inner-box .lower-box .content .post-meta li .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #fd6502;
  font-size: 14px;
}

.news-block-two .inner-box .lower-box .content h3 {
  position: relative;
  color: #232527;
  font-size: 24px;
  line-height: 1.7em;
  font-weight: 600;
}

.news-block-two .inner-box .lower-box .content h3 a {
  color: #232527;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block-two .inner-box .lower-box .content h3 a:hover {
  color: #fd6502;
}

.news-block-two .inner-box .lower-box .content .text {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 2em;
  margin-top: 8px;
}

.news-block-two .inner-box .lower-box .content .text p {
  position: relative;
  color: #848484;
  font-size: 14px;
  line-height: 2em;
  margin-bottom: 15px;
}

.blog-single .news-block-two {
  position: relative;
}

.blog-single .news-block-two .inner-box .lower-box {
  border: 0px;
}

.blog-single .news-block-two .inner-box .lower-box .content .text blockquote {
  position: relative;
  border-width: 2px;
  padding-left: 25px;
  margin: 25px 0px 30px;
}

.blog-single .news-block-two .inner-box .lower-box .content .text blockquote .blockquote-text {
  position: relative;
  color: #848484;
  font-size: 16px;
  font-style: italic;
  line-height: 1.8em;
  margin-bottom: 12px;
}

.blog-single .news-block-two .inner-box .lower-box .content .text blockquote .author {
  position: relative;
  color: #232527;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.blog-single .news-block-two .inner-box .lower-box .content .image-column {
  position: relative;
}

.blog-single .news-block-two .inner-box .lower-box .content .image-column .image {
  position: relative;
  margin-bottom: 30px;
}

.blog-single .news-block-two .inner-box .lower-box .content .image-column .image img {
  position: relative;
  width: 100%;
  display: block;
}

.blog-single .news-block-two .inner-box .lower-box h4 {
  position: relative;
  font-size: 20px;
  color: #232527;
  font-weight: 600;
  margin-bottom: 15px;
  font-family: 'Montserrat', sans-serif;
}

.blog-single .news-block-two .inner-box .lower-box .post-share-options {
  position: relative;
  margin-top: 40px;
  padding: 16px 0px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.blog-single .news-block-two .inner-box .lower-box .post-share-options .new-posts {
  position: relative;
  margin-top: 8px;
}

.blog-single .news-block-two .inner-box .lower-box .post-share-options .new-posts:before {
  position: absolute;
  content: '';
  left: 50%;
  top: 4px;
  width: 2px;
  height: 18px;
  margin-left: 8px;
  background-color: #ebebeb;
}

.blog-single .news-block-two .inner-box .lower-box .post-share-options .new-posts li {
  position: relative;
  margin-left: 20px;
  display: inline-block;
}

.blog-single .news-block-two .inner-box .lower-box .post-share-options .new-posts li a {
  position: relative;
  color: #848484;
  font-size: 14px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.blog-single .news-block-two .inner-box .lower-box .post-share-options .new-posts li a:hover {
  color: #f7b100;
}

/*Author Box*/

.author-box {
  position: relative;
  margin-top: 30px;
  margin-bottom: 60px;
}

.author-box h2 {
  position: relative;
  color: #232628;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 25px;
  margin-bottom: 40px;
  border-bottom: 1px solid #f2f2f2;
}

.author-box h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 55px;
  height: 2px;
  background-color: #f7b100;
}

.author-box .author-comment {
  position: relative;
  padding: 30px 30px 25px;
  background-color: #f7f7f7;
}

.author-box .author-comment .inner-box {
  position: relative;
  padding-left: 110px;
  min-height: 90px;
}

.author-box .author-comment .inner-box .image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 85px;
  margin-bottom: 20px;
}

.author-box .author-comment .inner-box h3 {
  position: relative;
  color: #232527;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.author-box .author-comment .inner-box .text {
  position: relative;
  color: #848484;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6em;
  margin-bottom: 10px;
}


/*Sidebar Widget*/

.sidebar-widget {
  position: relative;
  margin-bottom: 45px;
}

/*Sidebar Title*/

.sidebar-title {
  position: relative;
  margin-bottom: 30px;
}

.sidebar-title h2 {
  font-size: 24px;
  color: #232527;
  font-weight: 600;
  line-height: 1.2em;
  padding-bottom: 14px;
  text-transform: capitalize;
}

.sidebar-title h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 55px;
  height: 2px;
  background-color: #f7b100;
}

/*Search Box Widget*/

.sidebar .search-box .form-group {
  position: relative;
  margin: 0px;
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"] {
  position: relative;
  line-height: 28px;
  padding: 10px 50px 10px 20px;
  background: #f7f7f7;
  display: block;
  font-size: 14px;
  width: 100%;
  height: 50px;
  font-weight: 300;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sidebar .search-box .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 50px;
  width: 50px;
  display: block;
  font-size: 16px;
  color: #ffffff;
  line-height: 100%;
  background: #f7b100;
  font-weight: normal;
}

/*Blog Category*/

.blog-cat {
  position: relative;
  padding: 20px 25px 6px;
  border: 1px solid #eeeeee;
}

.blog-cat li {
  position: relative;
  margin-bottom: 15px;
}

.blog-cat li a {
  position: relative;
  font-size: 14px;
  font-weight: 300;
  display: block;
  padding-bottom: 12px;
  color: #999999;
  padding-left: 25px;
  border-bottom: 1px solid #f2f2f2;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.blog-cat li:last-child {
  margin-bottom: 0px;
}

.blog-cat li:last-child a {
  border: 0px;
}

.blog-cat li a span {
  float: right;
}

.blog-cat li a:before {
  position: absolute;
  content: '\f114';
  left: 0px;
  top: 0px;
  color: #afafaf;
  font-size: 15px;
  font-family: 'FontAwesome';
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.blog-cat li a:after {
  position: absolute;
  content: '\f115';
  left: 0px;
  top: 0px;
  color: #afafaf;
  font-size: 15px;
  opacity: 0;
  font-family: 'FontAwesome';
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.blog-cat li a:hover {
  color: #fd6502;
}

.blog-cat li a:hover::before {
  opacity: 0;
}

.blog-cat li a:hover::after {
  opacity: 1;
  color: #fd6502;
}

.blog-cat li a:hover::before {
  color: #fd6502;
}

/*Post Widget*/

.sidebar .popular-posts .post {
  position: relative;
  font-size: 14px;
  color: #666666;
  padding: 0px 0px;
  padding-left: 95px;
  min-height: 100px;
  margin-bottom: 22px;
  border-bottom: 1px solid #f2f2f2;
}

.sidebar .popular-posts .post:last-child {
  margin-bottom: 0px;
  min-height: 100px;
}

.sidebar .popular-posts .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 75px;
}

.sidebar .popular-posts .post:hover .post-thumb img {
  opacity: 0.70;
}

.sidebar .popular-posts .post .post-thumb img {
  display: block;
  width: 100%;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.sidebar .popular-posts .post .post-thumb .overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  text-align: center;
  background-color: rgba(19, 29, 51, 0.90);
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.sidebar .popular-posts .post:hover .post-thumb .overlay {
  opacity: 1;
}

.sidebar .popular-posts .post .post-thumb .overlay .icon {
  color: #ffffff;
  font-size: 20px;
  line-height: 75px;
}

.sidebar .popular-posts .post .text {
  position: relative;
  top: -4px;
  font-size: 16px;
  margin: 0px 0px 3px;
  font-weight: 600;
  color: #232527;
  line-height: 1.6em;
  text-transform: capitalize;
  font-family: 'Montserrat', sans-serif;
}

.sidebar .popular-posts .post .text a {
  color: #222222;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.sidebar .popular-posts .post a,
.sidebar .popular-posts .post a:hover {
  color: #f7b100;
}

.sidebar .popular-posts .post-meta {
  position: relative;
}

.sidebar .popular-posts .post-meta li {
  position: relative;
  font-size: 14px;
  color: #848484;
  padding-left: 22px;
  font-weight: 400;
  display: inline-block;
  margin-right: 10px;
}

.sidebar .popular-posts .post-meta li .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #f7b100;
  font-size: 14px;
  font-family: 'FontAwesome';
}

/*Popular Tags*/

.sidebar .popular-tags a {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 4px 15px 4px;
  margin: 0px 6px 10px 0px;
  color: #848484;
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
  background: none;
  font-weight: 300;
  border: 1px solid #f2f2f2;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.sidebar .popular-tags a:hover {
  color: #ffffff;
  border-color: #f7b100;
  background-color: #f7b100;
}

/*Instagram Widget*/

.instagram-widget {
  position: relative;
}

.instagram-widget .images-outer {
  position: relative;
}

.instagram-widget .images-outer .image-box {
  position: relative;
  width: 33.333%;
  float: left;
  margin-bottom: 20px;
  padding-right: 20px;
}

.instagram-widget .images-outer .image-box .image {
  position: relative;
}

.instagram-widget .images-outer .image-box .overlay-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  line-height: 84px;
  text-align: center;
  opacity: 0;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.80);
}

.instagram-widget .images-outer .image-box:hover .overlay-box {
  opacity: 1;
}

.instagram-widget .images-outer .image-box img {
  width: 100%;
  display: block;
}

/*** 

====================================================================
	Comments Area
====================================================================

 ***/

.sidebar-page-container .comments-area {
  position: relative;
  margin-top: 0px;
  margin-bottom: 50px;
}

.sidebar-page-container .group-title {
  position: relative;
  margin-bottom: 40px;
}

.sidebar-page-container .group-title h2 {
  position: relative;
  font-size: 24px;
  color: #232628;
  font-weight: 600;
  padding-bottom: 15px;
  text-transform: uppercase;
  border-bottom: 1px solid #f2f2f2;
}

.sidebar-page-container .group-title h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: -1px;
  width: 55px;
  height: 2px;
  background-color: #f7b100;
}

.sidebar-page-container .comments-area .comment-box {
  position: relative;
  margin-bottom: 30px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.sidebar-page-container .comments-area .comment-box:nth-child(1) {
  padding-top: 0px;
}

.sidebar-page-container .comments-area .comment-box:hover {
  border-color: #eb5310;
}

.sidebar-page-container .comments-area .comment {
  position: relative;
  font-size: 14px;
  padding: 0px 0px 0px 90px;
  min-height: 60px;
}

.sidebar-page-container .comments-area .comment .comment-inner {
  position: relative;
  padding: 15px 20px;
  border: 1px solid #f4f4f4;
}

.sidebar-page-container .comments-area .comment .comment-inner .rating {
  position: absolute;
  right: 15px;
  top: 15px;
}

.sidebar-page-container .comments-area .comment .comment-inner .rating .fa {
  display: inline-block;
  color: #f7b100;
}

.sidebar-page-container .comments-area .comment-box .author-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  margin-bottom: 20px;
  width: 60px;
}

.sidebar-page-container .comments-area .comment-box .author-thumb img {
  width: 60px;
  display: block;
}

.sidebar-page-container .comments-area .comment-info {
  position: relative;
  color: #232527;
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.sidebar-page-container .comments-area .comment-box .text {
  color: #848484;
  font-size: 14px;
  margin-top: 8px;
  font-weight: 300;
  line-height: 1.8em;
  margin-bottom: 0px;
}

/*Comment Form*/

.comment-form {
  position: relative;
}

.comment-form .group-title {
  margin-bottom: 20px;
}

.comment-form .rating-box {
  position: relative;
  margin-bottom: 20px;
}

.comment-form .rating-box .text {
  position: relative;
  font-size: 14px;
  color: #222222;
  margin-bottom: 10px;
}

.comment-form .rating-box .rating {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #ebebeb;
}

.comment-form .rating-box .rating:last-child {
  border: 0px;
}

.comment-form .rating-box .rating .fa {
  position: relative;
  margin-right: 2px;
  display: inline-block;
}

.comment-form .rating-box .rating a {
  position: relative;
  color: #d6d6d6;
  font-size: 14px;
  display: inline-block;
}

.comment-form .rating-box .rating a:hover {
  color: #f7b100;
}

.comment-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.comment-form .form-group label {
  position: relative;
  color: #848484;
  font-size: 16px;
  font-weight: 300;
}

.comment-form .form-group:last-child {
  margin-bottom: 0px;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="password"],
.comment-form .form-group input[type="tel"],
.comment-form .form-group input[type="email"],
.comment-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 28px;
  padding: 10px 15px;
  border: 1px solid #efefef;
  height: 50px;
  color: #848484;
  font-weight: 300;
  background: #ffffff;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="password"]:focus,
.comment-form .form-group input[type="tel"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus {
  border-color: #fa9928;
}

.comment-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 26px;
  padding: 10px 15px;
  color: #848484;
  border: 1px solid #efefef;
  height: 115px;
  font-weight: 300;
  background: #ffffff;
  resize: none;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.comment-form button {
  position: relative;
  margin-top: 20px;
}

.comment-form input:focus,
.comment-form select:focus,
.comment-form textarea:focus {
  border-color: #f7b100;
}

/*** 

====================================================================
	Contact Form Section
====================================================================

 ***/

.contact-form-section {
  position: relative;
  padding: 65px 0px;
}

.contact-form-section .sec-title .text {
  color: #848484;
  font-size: 16px;
  line-height: 1.8em;
  margin-top: 25px;
  margin-bottom: -15px;
}

/*Contact Form*/

.contact-form {
  position: relative;
}

.contact-form .row {
  margin: 0px -5px;
}

.contact-form .column {
  padding: 0px 5px;
}

.contact-form .form-group {
  position: relative;
  margin-bottom: 10px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="password"],
.contact-form textarea {
  display: block;
  width: 100%;
  height: 58px;
  font-size: 16px;
  color: #848484;
  line-height: 30px;
  padding: 10px 20px;
  font-weight: 400;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.contact-form textarea {
  height: 194px;
  resize: none;
}

.contact-form input:focus,
.contact-form select:focus,
.contact-form textarea:focus {
  border-color: #f7b100;
}

.contact-form button {
  margin-top: 20px;
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error {
  border-color: #ff0000 !important;
}

.contact-form label.error {
  line-height: 24px;
  padding: 5px 0px 0px;
  margin: 0px;
  text-transform: uppercase;
  font-size: 11px;
  color: #ff0000;
  font-weight: 500;
}

.contact-detailed-section {
  position: relative;
}

/*** 

====================================================================
	Contact Detailed Section
====================================================================

 ***/

.contact-detailed-section {
  position: relative;
  padding: 65px 0px 40px;
  background-color: #232628;
}

.headquater-box {
  position: relative;
  margin-bottom: 30px;
}

.headquater-box .inner-box {
  position: relative;
  padding: 30px 20px 20px;
  border: 1px solid #2f3335;
  background-color: #26292b;
}

.headquater-box .inner-box h2 {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  padding-bottom: 16px;
  margin-bottom: 26px;
}

.headquater-box .inner-box h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 55px;
  height: 2px;
  background-color: #f7b100;
}

/*Sales Department*/

.sales-department {
  position: relative;
  margin-bottom: 30px;
}

.sales-department .inner-box {
  position: relative;
  padding: 30px 20px;
  border: 1px solid #2f3335;
  background-color: #26292b;
}

.sales-department .inner-box h2 {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  padding-bottom: 16px;
  margin-bottom: 40px;
}

.sales-department .inner-box h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 55px;
  height: 2px;
  background-color: #f7b100;
}

.department-author {
  position: relative;
  margin-bottom: 20px;
}

.department-author .inner-box {
  position: relative;
  padding: 25px 18px;
  border: 1px solid #323537;
  background-color: #2b2e30;
}

.department-author .inner-box .content {
  position: relative;
  padding-left: 85px;
}

.department-author .inner-box .content .image {
  position: absolute;
  width: 67px;
  left: 0px;
  top: 0px;
}

.department-author .inner-box .content h3 {
  position: relative;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 1em;
  margin-bottom: 10px;
}

.department-author .inner-box .content ul {
  position: relative;
}

.department-author .inner-box .content ul li {
  position: relative;
  color: #848484;
  font-size: 14px;
  padding-left: 22px;
}

.department-author .inner-box .content ul li .icon {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  color: #f7b100;
  font-size: 14px;
}

.sales-department .inner-box .owl-nav {
  display: none;
}

.sales-department .inner-box .owl-dots {
  position: absolute;
  right: 0px;
  top: -80px;
}

.sales-department .inner-box .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  margin: 0px 4px;
  border: 1px solid #4a4d4e;
  border-radius: 50%;
  display: inline-block;
}

.sales-department .inner-box .owl-dots .owl-dot.active,
.sales-department .inner-box .owl-dots .owl-dot:hover {
  background-color: #f7b100;
  border-color: #f7b100;
}

/*Hours Block*/

.hours-block {
  position: relative;
}

.hours-block .inner-box {
  position: relative;
  padding: 20px 18px;
  border: 1px solid #323537;
  background-color: #26292b;
}

.hours-block .inner-box h2 {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  padding-bottom: 16px;
  margin-bottom: 40px;
}

.hours-block .inner-box h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 55px;
  height: 2px;
  background-color: #f7b100;
}

.hours-block .inner-box ul {
  position: relative;
}

.hours-block .inner-box ul li {
  position: relative;
  color: #848484;
  font-size: 14px;
  padding: 10px 20px;
}

.hours-block .inner-box ul li:nth-child(2n + 0) {
  background-color: #2b2e30;
}

.hours-block .inner-box ul li span {
  float: right;
}

.hours-block .inner-box ul li .closed {
  color: #f7b100;
  font-size: 14px;
}

/*** 

====================================================================
	Map Section Style
====================================================================

***/

.map-data {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
}

.map-data h6 {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
  color: #121212;
}

.map-canvas {
  height: 460px;
}

.map-section .google-map {
  height: 460px;
}

/*** 

====================================================================
	Subscribe Section
====================================================================

***/

.subscribe-section {
  position: relative;
  padding: 65px 0px 60px;
}

.subscribe-section .text {
  position: relative;
  color: #848484;
  font-size: 16px;
  text-align: center;
  margin-top: -15px;
  margin-bottom: 40px;
}

.subscribe-form {
  position: relative;
  max-width: 970px;
  margin: 0 auto;
}

.subscribe-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.subscribe-form .form-group input {
  position: relative;
  width: 100%;
  height: 55px;
  padding: 10px 20px;
  border: 1px solid #f2f2f2;
}

.subscribe-form .form-group button {
  width: 100%;
}

/*** 

====================================================================
	Inventory Section
====================================================================

***/

.inventory-section {
  position: relative;
  padding: 0px 40px;
  margin: auto;

}

.inventory-section .column {
  margin-bottom: 30px;
  background: #f7f7f7;
}

.inventory-section .form-column .select-cars-tabs .prod-tabs {
  margin-bottom: 0px;
}


.inventory-single .form-column .select-cars-tabs {
  margin-bottom: 50px;
}

.sort-form {
  position: relative;
}


.sort-form .form-group {
  position: relative;
  margin-bottom: 20px;
  width: 250px;
  max-width: 100%;
}

.sort-form .form-group label {
  position: absolute;
  left: 0px;
  top: 10px;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
}


.sort-form select option:hover {
  color: #ffd658;
  background-color: #000;
}

.sort-form .form-group {
  position: relative;
  margin-bottom: 20px;
  width: 250px;
  max-width: 100%;
}

.sort-form .form-group label {
  position: absolute;
  left: 0px;
  top: 10px;
  color: #848484;
  font-size: 14px;
  font-weight: 200;

}

.sort-form .form-group .ui-selectmenu-button.ui-button {
  position: relative;
  margin-left: 60px;
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  height: 45px;
  padding: 10px 18px;
  line-height: 24px;
  color: #333333;
  border-radius: 0px;
  border: 1px solid #e5e5e5;

}

.inventory-section .layout-box {
  position: relative;
  padding-bottom: 5px;
  /* margin-bottom:50px;
	border-bottom:1px solid #f2f2f2; */
}

.inventory-section .layout-box .view-options {
  position: relative;
}

.inventory-section .layout-box .view-options li {
  position: relative;
  margin-left: 6px;
  display: inline-block;
}

.inventory-section .layout-box .view-options li a {
  position: relative;
  /* width:45px; */
  width: 35px;
  /* height:45px; */
  height: 35px;
  color: #232527;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  background-color: #f7f7f7;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.inventory-section .layout-box .view-options li a:hover,
.inventory-section .layout-box .view-options li.active a {
  color: #ffffff;
  background-color: #f7b100;
}

/*** 

====================================================================
	Shop Section
====================================================================

***/

.shop-section {
  position: relative;
  padding: 70px 0px;
}

.shop-sidebar-widget {
  position: relative;
  margin-bottom: 50px;
}

.shop-sidebar-widget .inner-box {
  position: relative;
  padding: 25px 20px;
  background-color: #f7f7f7;
}

.shop-sidebar-widget .inner-box h2 {
  position: relative;
  color: #232527;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 16px;
  margin-bottom: 20px;
}

.shop-sidebar-widget .inner-box h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 55px;
  height: 2px;
  background-color: #f7b100;
}

.category-link {
  position: relative;
}

.category-link li {
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
}

.category-link li:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border: 0px;
}

.category-link li a {
  position: relative;
  color: #848484;
  font-size: 14px;
  padding-left: 18px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.category-link li a:before {
  position: absolute;
  content: '\f105';
  left: 0px;
  font-family: 'FontAwesome';
}

.category-link li a:hover {
  color: #f7b100;
}

/*Sidebar Range slider */

.range-slider-one {
  position: relative;
  min-height: 48px;
}

.range-slider-one .title {
  position: relative;
  float: left;
  color: #848484;
  font-size: 14px;
  font-weight: 300;
  margin-top: 8px;
}

.range-slider-one .title:before {
  position: absolute;
  content: '$';
  right: -14px;
  top: 0px;
  color: #848484;
  font-size: 14px;
  font-weight: 300;
}

.range-slider-one .input {
  float: right;
  left: 8px;
  max-width: 75px;
  padding-left: 8px;
  margin-top: 8px;
  position: relative;
}

.range-slider-one .input input {
  background: none;
  color: #848484;
  font-size: 16px;
  font-weight: 300;
  width: auto;
  text-align: left;
}

.range-slider-one .ui-widget.ui-widget-content {
  height: 6px;
  border: none;
  margin-bottom: 32px;
  background: #e8e8e8;
}

.range-slider-one .ui-slider .ui-slider-range {
  top: 0px;
  height: 6px;
  background: #f7b100;
}

.range-slider-one .ui-state-default,
.range-slider-one .ui-widget-content .ui-state-default {
  top: -4px;
  width: 14px;
  height: 14px;
  border: 3px solid #f7b100;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

.range-slider-one .theme-btn {
  padding: 6px 16px;
  color: #232527;
  border-color: #e8e8e8;
  background-color: #e8e8e8;
}

/*Sidebar Realated Posts */

.sidebar .shop-sidebar-widget .post {
  position: relative;
  padding-left: 105px;
  min-height: 85px;
  margin-bottom: 30px;
}

.sidebar .shop-sidebar-widget .post:last-child {
  margin-bottom: 0px;
}

.sidebar .shop-sidebar-widget .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 83px;
  border: 1px solid #e8e8e8;
}

.sidebar .shop-sidebar-widget .post .post-thumb img {
  display: block;
  width: 100%;
}

.sidebar .shop-sidebar-widget .post h4 {
  top: -2px;
  font-size: 16px;
  font-weight: 600;
  color: #232527;
  text-transform: capitalize;
}

.sidebar .shop-sidebar-widget .post h4 a {
  color: #232527;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.sidebar .shop-sidebar-widget .post a,
.sidebar .shop-sidebar-widget .post a:hover {
  color: #ff6600;
}

.sidebar .shop-sidebar-widget .post .price {
  text-decoration: none;
  color: #f7b100;
  font-size: 18px;
}

.sidebar .shop-sidebar-widget .post .price span {
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 400;
  color: #848484;
  margin-right: 8px;
  text-decoration: line-through;
}

.sidebar .shop-sidebar-widget .post .rating {
  margin-bottom: 3px;
}

.sidebar .shop-sidebar-widget .post .rating .fa {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
  line-height: 26px;
  color: #f7b100;
}

/*** 

====================================================================
	Shop Style
====================================================================

***/

.our-shops .items-sorting {
  position: relative;
  margin-bottom: 10px;
}

.our-shops .items-sorting .results-column,
.our-shops .items-sorting .select-column {
  margin-bottom: 20px;
}

.our-shops .items-sorting .results-column h4 {
  font-size: 14px;
  font-weight: 300;
  color: #848484;
  line-height: 24px;
  padding: 13px 0px;
  text-align: right;
}

.our-shops .items-sorting .form-group {
  margin-bottom: 0px;
}

.our-shops .items-sorting select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 9px 15px 9px;
  height: 50px;
  font-size: 16px;
  border: 1px solid #eeeeee;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  background: url(../images/icons/icon-select.png) right center no-repeat;
  color: #999999;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.our-shops .items-sorting select option {
  text-indent: 15px;
}

/*Shop Item*/

.shop-item {
  position: relative;
  margin-bottom: 40px;
}

.shop-item .inner-box {
  position: relative;
}

.shop-item .inner-box .image {
  position: relative;
  background-color: #f8f8f8;
}

.shop-item .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
}

.shop-item .inner-box .lower-content {
  position: relative;
  border: 1px solid #f7f7f7;
}

.shop-item .inner-box .lower-content .upper-box {
  position: relative;
  padding: 15px 18px;
}

.shop-item .inner-box .lower-content .upper-box h3 {
  position: relative;
  color: #232527;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.shop-item .inner-box .lower-content .upper-box h3 a {
  color: #232527;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.shop-item .inner-box .lower-content .upper-box h3 a:hover {
  color: #f7b100;
}

.shop-item .inner-box .lower-content .upper-box .price {
  position: relative;
  color: #f7b100;
  font-size: 16px;
}

.shop-item .inner-box .lower-content .lower-box {
  position: relative;
  padding: 12px 18px;
  border-top: 1px dashed #f7f7f7;
}

.shop-item .inner-box .lower-content .lower-box .add-cart {
  position: relative;
  color: #848484;
  font-weight: 600;
  padding-left: 20px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  font-family: 'Montserrat', sans-serif;
}

.shop-item .inner-box .lower-content .lower-box .add-cart:hover {
  color: #f7b100;
  text-decoration: underline;
}

.shop-item .inner-box .lower-content .lower-box .add-cart:before {
  position: absolute;
  content: '\f07a';
  left: 0px;
  top: 2px;
  font-size: 14px;
  line-height: 1em;
  font-family: 'FontAwesome';
}

.shop-item .inner-box .lower-content .lower-box .rating {
  position: relative;
  color: #f7b100;
  font-size: 12px;
}

.product-details .basic-details {
  position: relative;
  margin-bottom: 20px;
}

.product-details .image-column,
.product-details .info-column {
  margin-bottom: 20px;
}

.product-details .image-column .image-box img {
  position: relative;
  display: block;
  width: 100%;
  background-color: #f7f7f7;
}

.product-details .basic-details .details-header {
  position: relative;
  margin-bottom: 16px;
}

.product-details .basic-details .details-header h4 {
  font-size: 24px;
  font-weight: 600;
  margin: -5px 0px 5px;
  line-height: 1.4em;
  color: #232527;
}

.product-details .basic-details .details-header h4 a {
  color: #333333;
}

.product-details .basic-details .details-header .rating {
  font-size: 13px;
  color: #f7b100;
  margin-bottom: 15px;
  display: inline-block;
}

.product-details .basic-details .details-header .rating .fa {
  display: inline-block;
  margin-right: 0px;
}

.product-details .basic-details .details-header .rating .txt {
  font-size: 14px;
  padding-left: 10px;
  color: #777777;
}

.product-details .basic-details .details-header .reviews {
  position: relative;
  margin-left: 10px;
  color: #848484;
  font-style: italic;
  display: inline-block;
}

.product-details .basic-details .details-header .item-price {
  font-size: 24px;
  font-weight: 400;
  color: #f7b100;
  line-height: 24px;
  letter-spacing: 1px;
  padding-bottom: 24px;
  border-bottom: 1px solid #f2f2f2;
}

.product-details .basic-details .prod-info {
  margin-bottom: 15px;
  line-height: 1.6em;
  font-size: 13px;
}

.product-details .basic-details .prod-info strong {
  font-weight: 700;
  color: #111111;
}

.product-details .basic-details .text {
  margin-bottom: 20px;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
}

.product-details .basic-details .location {
  position: relative;
  color: #232527;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 14px;
  font-family: 'Montserrat', sans-serif;
}

.product-details .basic-details .item-quantity .field-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  display: inline-block;
  padding-right: 20px;
}

.product-details .basic-details .quantity-spinner,
.cart-section input.quantity-spinner {
  line-height: 24px;
  padding: 10px 15px !important;
  height: 46px !important;
  box-shadow: none !important;
}

.product-details .basic-details .bootstrap-touchspin .input-group-btn-vertical>.btn,
.cart-section .bootstrap-touchspin .input-group-btn-vertical>.btn {
  padding: 11px 10px;
  background: #f4f5f6;
}

.product-details .bootstrap-touchspin .input-group-btn-vertical i,
.cart-section .bootstrap-touchspin .input-group-btn-vertical i {
  top: 6px;
}

.product-details .basic-details .item-quantity {
  position: relative;
  float: left;
  width: 85px;
  margin-bottom: 25px;
  margin-right: 20px;
}

.product-details .basic-details .item-quantity .field-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  display: inline-block;
  padding-right: 20px;
}

.product-details .basic-details .quantity-spinner,
.cart-table input.quantity-spinner {
  font-size: 18px;
  line-height: 24px;
  padding: 10px 15px !important;
  height: 54px !important;
  box-shadow: none !important;
}

.product-details .basic-details .bootstrap-touchspin .input-group-btn-vertical>.btn,
.cart-table .bootstrap-touchspin .input-group-btn-vertical>.btn {
  padding: 13px 10px;
  background: #f4f5f6;
  border-radius: 0px;
}

.product-details .bootstrap-touchspin .input-group-btn-vertical i,
.cart-table .bootstrap-touchspin .input-group-btn-vertical i {
  top: 8px;
}

.product-details .basic-details .add-to-cart {
  padding: 13px 37px;
}

/*Pincode Form*/

.pincode-form {
  position: relative;
  margin-bottom: 8px;
}

.pincode-form .form-group {
  margin-bottom: 0px;
}

.pincode-form .form-group label {
  position: relative;
  color: #848484;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 0px;
}

.pincode-form .form-group input[type="text"] {
  position: relative;
  line-height: 14px;
  padding: 4px 10px 4px 15px;
  border: 1px solid #f4f4f4;
  background: none;
  font-size: 16px;
  width: 170px;
  height: 40px;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

/*** 

====================================================================
	Product Tabs Style
====================================================================

***/

.shop-single .product-details .prod-tabs {
  position: relative;
  margin-bottom: 60px;
}

.shop-single .product-details .prod-tabs .tab-btns {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #eeeeee;
}

.shop-single .product-details .prod-tabs .tab-btns .tab-btn {
  position: relative;
  top: 1px;
  display: block;
  float: left;
  margin-right: 5px;
  font-size: 16px;
  background: #f7f7f7;
  color: #222222;
  border: 1px solid #f4f4f4;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  padding: 12px 18px 10px;
  transition: all 500ms ease;
  font-family: 'Montserrat', sans-serif;
}

.shop-single .product-details .prod-tabs .tab-btns .tab-btn.active-btn:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: -1px;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
}

.shop-single .product-details .prod-tabs .tab-btns .tab-btn:hover,
.shop-single .product-details .prod-tabs .tab-btns .tab-btn.active-btn {
  color: #f7b100;
  background: #ffffff;
}

.shop-single .product-details .prod-tabs .tabs-content {
  position: relative;
  padding: 30px 30px 30px;
  border: 1px solid #f0f0f0;
  border-top: 0px;
}

.shop-single .product-details .prod-tabs .tabs-content .tab {
  position: relative;
  display: none;
  border-top: 0px;
}

.shop-single .product-details .prod-tabs .tabs-content .tab.active-tab {
  display: block;
}

.prod-tabs .tabs-content .tab .title {
  position: relative;
  color: #232527;
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 25px;
  font-family: 'Montserrat', sans-serif;
}

/*Comment Form*/

.shop-comment-form {
  position: relative;
}

.shop-comment-form h2 {
  position: relative;
  color: #232628;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
  padding-bottom: 15px;
  text-transform: uppercase;
  border-bottom: 1px solid #f2f2f2;
}

.shop-comment-form h2:before {
  position: absolute;
  content: '';
  left: 0px;
  bottom: -1px;
  width: 55px;
  height: 2px;
  background-color: #f7b100;
}

.shop-comment-form .group-title {
  margin-bottom: 20px;
}

.shop-comment-form .rating-box {
  position: relative;
  margin-bottom: 30px;
}

.shop-comment-form .rating-box .text {
  position: relative;
  font-size: 16px;
  color: #848484;
  margin-bottom: 10px;
}

.shop-comment-form .rating-box .rating {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #ebebeb;
}

.shop-comment-form .rating-box .rating:last-child {
  border-right: 0px;
}

.shop-comment-form .rating-box .rating .fa {
  position: relative;
  margin-right: 2px;
  display: inline-block;
}

.shop-comment-form .rating-box .rating a {
  position: relative;
  color: #d6d6d6;
  font-size: 14px;
  display: inline-block;
}

.shop-comment-form .rating-box .rating a:hover {
  color: #f7b100;
}

.shop-comment-form .form-group {
  position: relative;
  margin-bottom: 15px;
}

.shop-comment-form .form-group label {
  position: relative;
  color: #848484;
  font-size: 14px;
  font-weight: 400;
}

.shop-comment-form .form-group:last-child {
  margin-bottom: 0px;
}

.shop-comment-form .form-group input[type="text"],
.shop-comment-form .form-group input[type="password"],
.shop-comment-form .form-group input[type="tel"],
.shop-comment-form .form-group input[type="email"],
.shop-comment-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 28px;
  padding: 10px 15px;
  border: 1px solid #efefef;
  height: 50px;
  color: #848484;
  font-weight: 300;
  background: #ffffff;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.shop-comment-form .form-group input[type="text"]:focus,
.shop-comment-form .form-group input[type="password"]:focus,
.shop-comment-form .form-group input[type="tel"]:focus,
.shop-comment-form .form-group input[type="email"]:focus,
.shop-comment-form .form-group select:focus,
.shop-comment-form .form-group textarea:focus {
  border-color: #f7b100;
}

.shop-comment-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 26px;
  padding: 10px 15px;
  color: #848484;
  border: 1px solid #efefef;
  height: 115px;
  font-weight: 300;
  background: #ffffff;
  resize: none;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.shop-comment-form button {
  position: relative;
  margin-top: 10px;
}

.shop-comment-form input:focus,
.shop-comment-form select:focus,
.shop-comment-form textarea:focus {
  border-color: #f06529;
}

/*Related Products*/

.related-products {
  position: relative;
}

.related-products h2 {
  position: relative;
  font-size: 24px;
}

/*** 

====================================================================
	Login / Register Section
====================================================================

***/

.register-section {
  position: relative;
  padding: 80px 0px 40px;
}

.register-section .form-column {
  margin-bottom: 40px;
}

.register-section .form-column .form-title {
  position: relative;
  margin-bottom: 40px;
}

.register-section .form-column .form-title h2 {
  color: #232527;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 15px;
  text-transform: uppercase;
  border-bottom: 1px solid #f2f2f2;
}

.register-section .form-column .form-title h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 50px;
  height: 2px;
  background-color: #f7b100;
}

.styled-form {
  position: relative;
}

.styled-form .submit-text {
  font-size: 15px;
  color: #9e9e9e;
  font-weight: 400;
  margin-top: 10px;
}

.styled-form .form-group {
  position: relative;
  margin-bottom: 30px;
  font-size: 14px;
  color: #848484;
}

.checkout-section .styled-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.styled-form .form-group .check-box {
  line-height: 24px;
  font-size: 15px;
  font-weight: normal;
  padding-top: 5px;
  color: #272727;
}

.styled-form .form-group .check-box label {
  position: relative;
  top: -1px;
  font-weight: normal;
  padding: 0px;
  font-size: 15px;
  cursor: pointer;
}

.styled-form .pull-left label,
.styled-form .pull-right label {
  cursor: pointer;
}

.styled-form .form-group .adon-icon {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
  color: #999999;
  z-index: 2;
}

.styled-form .form-group .field-label {
  display: block;
  color: #272727;
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 7px;
  font-weight: 400;
}

.styled-form input[type="text"],
.styled-form input[type="email"],
.styled-form input[type="password"],
.styled-form input[type="tel"],
.styled-form input[type="number"],
.styled-form input[type="url"],
.styled-form select,
.styled-form textarea {
  position: relative;
  display: block;
  width: 100%;
  background: #ffffff;
  line-height: 30px;
  padding: 8px 20px;
  height: 50px;
  border: 2px solid #f4f4f4;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.checkout-section .styled-form input[type="text"],
.checkout-section .styled-form input[type="email"],
.checkout-section .styled-form input[type="password"],
.checkout-section .styled-form select {
  line-height: 26px;
  height: 44px;
}

.styled-form select {
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  background: #ffffff url(../images/icons/icon-select.png) right center no-repeat;
  cursor: pointer;
}

.styled-form select option {
  text-indent: 20px;
}

.styled-form textarea {
  resize: none;
  height: 110px;
}

.styled-form input:focus,
.styled-form select:focus,
.styled-form textarea:focus {
  border-color: #f7b100;
}

.styled-form .form-group .theme-btn {
  min-width: 150px;
  text-align: center;
  padding: 10px 30px;
}

.styled-form .remember-me {
  position: relative;
  color: #232527;
  font-size: 16px;
  font-weight: 600;
}

.social-links-two a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  background: #fc721e;
  margin: 0px 0px 0px 15px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.social-links-two a:hover {
  opacity: 0.80;
}

.social-links-two .facebook {
  background: #3b5998;
}

.social-links-two .twitter {
  background: #33ccfe;
}

.social-links-two .google-plus {
  background: #dd4b39;
}

/*** 

====================================================================
	Cart Section style
====================================================================

***/

.cart-section {
  position: relative;
  padding: 80px 0px;
}

.cart-section .cart-outer {
  position: relative;
}

.cart-section .table-outer {
  position: relative;
  width: 100%;
  overflow-x: auto;
}

.cart-section .cart-table {
  width: 100%;
  min-width: 900px;
}

.cart-table .cart-header {
  position: relative;
  width: 100%;
  text-transform: capitalize;
  font-size: 16px;
  border-radius: 0px;
  background: #f7f7f7;
  color: #1f1f1f;
}

.cart-table thead tr th {
  line-height: 24px;
  padding: 20px 15px;
  min-width: 120px;
  font-weight: 600;
  color: #232527;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.cart-table thead tr th.prod-column {
  text-align: left;
  padding-left: 40px;
}

.cart-table tbody tr td {
  line-height: 24px;
  padding: 30px 10px 20px;
  min-width: 100px;
}

.cart-table tbody tr .qty {
  width: 90px;
  padding-right: 40px;
}

.cart-table tbody tr .qty .quantity-spinner {
  background: #f5f5f5;
}

.cart-table tbody tr .prod-column .column-box {
  position: relative;
  min-height: 90px;
  padding-left: 115px;
  padding-top: 32px;
  text-align: left;
}


.column.default-column .cart-table tbody tr .qty {
  width: 110px;
  padding-right: 20px;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb {
  position: absolute;
  width: 85px;
  border: 1px solid #f0f0f0;
  left: 0px;
  top: 0px;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb img {
  display: block;
  max-width: 100%;
}

.cart-table tbody tr .prod-column .column-box h3 {
  font-size: 16px;
  color: #232527;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: 'Montserrat', sans-serif;
}

.cart-table tbody tr .sub-total {
  font-weight: 400;
  color: #f7b100;
  font-size: 16px;
}

.cart-table tbody tr .remove-btn {
  position: relative;
  font-size: 18px;
  color: #ececec;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 30px;
  display: inline-block;
  border: 2px solid #ececec;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.cart-table tbody tr .remove-btn .fa {
  position: relative;
  top: 2px;
  font-size: 10px;
  line-height: 14px;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  background: #f0f0f0;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  text-indent: 100px;
}

.cart-table tbody tr .remove input[type=checkbox] {
  position: relative;
  top: 2px;
}

.cart-table tbody tr .remove label {
  color: #848484;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.cart-table tbody tr .remove-btn:hover {
  color: #f7b100;
  border-color: #f7b100;
}

.cart-table tbody tr .remove-btn:hover .fa {
  text-indent: 0px;
  background: #fa9928;
}

.cart-table tbody tr {
  border-bottom: 1px solid #f0f0f0;
}

.cart-table tbody tr td {
  vertical-align: middle;
}

.cart-table tbody tr td.price {
  font-size: 16px;
  font-weight: 300;
  color: #848484;
}

.cart-table tbody tr td .quantity-spinner {
  padding: 5px 0px 5px 20px;
  line-height: 24px;
  height: 34px;
  display: block;
  width: 100%;
  position: relative;
}

.cart-table tbody .available-info {
  position: relative;
  padding-left: 50px;
  color: #848484;
  font-size: 14px;
}

.cart-table tbody .available-info .icon {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  background: #f7b100;
  border-radius: 50%;
}

.cart-section .coupon-outer {
  position: relative;
  margin: 40px 0px 40px;
  padding: 0px 30px;
}

.cart-section .coupon-outer .cart-btn {
  color: #222222;
  font-size: 14px;
  padding: 11px 33px;
  font-weight: 600;
  letter-spacing: 1px;
  background: none;
  border: 2px solid #f2f2f2;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: 'Montserrat', sans-serif;
}

.cart-section .apply-coupon {
  position: relative;
}

.cart-section .apply-coupon .form-group {
  position: relative;
  float: left;
  margin-right: 15px;
}

.cart-section .apply-coupon .form-group input[type="text"] {
  display: block;
  line-height: 26px;
  padding: 12px 20px;
  border: 2px solid #f7f7f7;
  height: 50px;
  width: 230px;
  background: none;
}

.cart-section .apply-coupon .form-group button {
  padding: 11px 30px;
}

.cart-section .estimate-form {
  position: relative;
}

.cart-section .sec-title {
  margin-bottom: 15px;
}

.cart-section .sec-title h2 {
  font-size: 24px;
  color: #222222;
  border: 0px;
}

.cart-section .sec-title h2:before {
  display: none;
}

.cart-section .estimate-form .row {
  margin-left: -7px;
  margin-right: -7px;
}

.cart-section .estimate-form .row .form-group {
  padding: 0px 7px !important;
  margin-bottom: 30px;
}

.cart-section .totals-table {
  position: relative;
  border: 2px solid #f0f0f0;
  border-radius: 2px;
  margin-bottom: 30px;
}

.cart-section .totals-table .col {
  position: relative;
  display: block;
  float: left;
  padding: 12px 18px;
  line-height: 24px;
  width: 50%;
  font-weight: 400;
  font-size: 16px;
  color: #848484;
}

.cart-section .totals-table .col-title {
  font-size: 16px;
  color: #222222;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  border-right: 1px solid #f0f0f0;
}

.cart-section .totals-table li {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
}

.cart-section .totals-table li:last-child {
  border-bottom: none;
}

.cart-section .cart-note {
  position: relative;
  margin-bottom: 50px;
  padding: 16px 20px;
  text-align: center;
  border: 1px dashed #b2dd4c;
  background: #f4f4f4;
  line-height: 1.7em;
}

.product-details .basic-details .item-quantity input[type="text"],
.cart-table tbody tr .qty input[type="text"] {
  position: relative;
  line-height: 46px;
  height: 46px;
  background: #f4f4f4;
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
  height: 24px;
  background: #e9e9e9;
}

.bootstrap-touchspin .input-group-btn-vertical i {
  left: 6px;
  top: 6px;
}

/*Shipping Form*/

.shipping-form .sec-title h2 {
  border: 0px;
}

.shipping-form .sec-title h2:before {
  display: none;
}

.shipping-form .form-group {
  position: relative;
  margin-bottom: 30px;
  padding: 0px 15px;
}

.shipping-form .form-group .ui-selectmenu-button.ui-button {
  background-color: #ffffff;
  height: 45px;
  line-height: 24px;
  z-index: 10;
  border-width: 2px;
  border-color: #f2f2f2;
  background-position: right top;
  background-repeat: no-repeat;
  background-image: url(../images/icons/arrow-right.jpg);
}

.shipping-form .form-group .ui-button .ui-icon {
  opacity: 0;
}

.shipping-form .form-group input,
.shipping-form .form-group select {
  position: relative;
  display: block;
  width: 100%;
  line-height: 33px;
  padding: 10px 20px;
  height: 45px;
  font-size: 14px;
  font-weight: 300;
  background: #ffffff;
  border: 2px solid #f2f2f2;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.shipping-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 30px;
  padding: 10px 20px;
  height: 120px;
  resize: none;
  font-size: 16px;
  font-weight: 300;
  background: #f7f7f7;
  border: 1px solid #f4f4f4;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.shipping-form .form-group input:focus,
.shipping-form .form-group textarea:focus {
  border-color: #f7b100;
}

.shipping-form .form-group .total-btn {
  color: #222222;
  font-size: 14px;
  padding: 11px 33px;
  font-weight: 600;
  letter-spacing: 1px;
  background: none;
  border: 2px solid #f2f2f2;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: 'Montserrat', sans-serif;
}

/*** 

====================================================================
	CheckOut Sectioon
====================================================================

***/

.checkout-page {
  position: relative;
  padding: 75px 0px 70px;
}

.checkout-form {
  position: relative;
}

.checkout-form .checkout-title {
  position: relative;
}

.checkout-form .checkout-title h2 {
  position: relative;
  color: #232628;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f2f2f2;
  text-transform: uppercase;
}

.checkout-form .checkout-title h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 55px;
  height: 2px;
  background-color: #f7b100;
}

.checkout-form .form-group {
  position: relative;
  margin-bottom: 25px;
}

.checkout-form .form-group .field-label {
  display: block;
  line-height: 24px;
  margin-bottom: 6px;
  text-transform: capitalize;
  color: #232527;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.checkout-form .form-group .field-label sup {
  top: -1px;
  font-size: 16px;
}

.checkout-form input[type="text"],
.checkout-form input[type="email"],
.checkout-form input[type="password"],
.checkout-form input[type="tel"],
.checkout-form input[type="number"],
.checkout-form input[type="url"],
.checkout-form select,
.checkout-form textarea {
  position: relative;
  display: block;
  width: 100%;
  background: #ffffff;
  line-height: 22px;
  padding: 12px 15px;
  height: 48px;
  border: 2px solid #f4f4f4;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.checkout-form textarea {
  resize: none;
  height: 120px !important;
}

.checkout-form input:focus,
.checkout-form select:focus,
.checkout-form textarea:focus {
  border-color: #f7b100;
}

.checkout-page .check-box {
  line-height: 24px;
  font-size: 14px;
  font-weight: normal;
  padding-top: 5px;
}

.checkout-page .check-box label {
  position: relative;
  top: -1px;
  padding: 0px;
  font-size: 16px;
  cursor: pointer;
  color: #f7b100;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.checkout-page .lower-content {
  margin-top: 30px;
}

.checkout-page .lower-content .order-column {
  margin-bottom: 40px;
}

.checkout-page .lower-content .column h2 {
  position: relative;
  color: #232628;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.checkout-page .lower-content .column h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 55px;
  height: 2px;
  background-color: #f7b100;
}

.checkout-page .lower-content .order-column .cart-outer {
  overflow-x: auto;
}

.checkout-page .lower-content .order-column .cart-table {
  width: 100%;
  border-right: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
}

.cart-table thead tr th {
  line-height: 24px;
  padding: 13px 30px;
}

.cart-table thead tr th .fa {
  font-size: 18px;
}

.cart-table tbody tr td {
  line-height: 24px;
  padding: 30px 0px 30px 30px;
}

.cart-table tbody tr .qty input {
  width: 45px;
  height: 50px;
  color: #222222;
  font-size: 18px;
  line-height: 50px;
  background: #f7f7f7;
  text-align: center;
}

.cart-table tbody tr .prod-column .column-box {
  position: relative;
  min-height: 70px;
  padding-left: 100px;
  padding-top: 30px;
  text-align: left;
  min-width: 230px;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb {
  position: absolute;
  width: 80px;
  left: 0px;
  top: 0px;
  background-color: #f4f4f4;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb img {
  display: block;
  width: 100%;
}

.cart-table tbody tr .prod-column .column-box h4 {
  font-size: 16px;
  color: #232527;
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.cart-table tbody tr .sub-total {
  font-weight: 400;
  font-size: 16px;
  color: #f7b100;
}

.cart-table tbody tr .price {
  font-weight: 700;
  font-size: 14px;
  color: #777777;
}

/*Total Table*/

.checkout-page .lower-content .totals-table {
  position: relative;
  margin-bottom: 40px;
  border: 1px solid #eeeeee;
}

.checkout-page .lower-content .totals-table li .col {
  padding: 12px 20px;
}

.checkout-page .lower-content .totals-table li {
  line-height: 24px;
  border-bottom: 1px solid #eeeeee;
}

.checkout-page .lower-content .totals-table li .col.title {
  font-weight: 500;
  color: #222222;
  font-size: 16px;
  border-right: 1px solid #eeeeee;
}

.checkout-page .lower-content .totals-table li:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}

.checkout-page .lower-content .totals-table .col {
  position: relative;
  display: block;
  float: left;
  line-height: 24px;
  width: 50%;
  color: #848484;
  font-weight: 300;
  font-size: 16px;
}

/*Payment Option*/

.checkout-page .payment-options {
  position: relative;
  padding: 30px 20px;
  background-color: #f7f7f7;
}

.checkout-page .payment-options li {
  position: relative;
  margin-bottom: 15px;
}

.checkout-page .payment-options li .radio-option {
  position: relative;
}

.checkout-page .payment-options li .radio-option label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  font-weight: 500 !important;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: #333333;
  cursor: pointer;
}

.checkout-page .payment-options li .radio-option label strong {
  font-weight: 600;
  color: #232527;
  font-size: 16px;
}

.checkout-page .payment-options li .radio-option label strong a {
  font-size: 16px;
  margin-left: 30px;
  text-decoration: underline;
}

.checkout-page .payment-options li .radio-option input[type="radio"] {
  position: absolute;
  left: 0px;
  top: 3px;
}

.checkout-page .payment-options li .radio-option label .small-text {
  display: block;
  padding-top: 5px;
  letter-spacing: 0px;
  text-transform: none;
  font-size: 14px;
  color: #848484;
  font-weight: 300;
  line-height: 1.7em;
}

.checkout-page .payment-options li .radio-option label img {
  position: relative;
  top: -3px;
  display: inline-block;
  max-width: 100%;
  padding-left: 30px;
}

.checkout-page .place-order {
  padding: 11px 39px;
  margin-left: 30px;
  margin-top: 10px;
}

/*** 

====================================================================
	Error Section
====================================================================

***/

.error-section {
  position: relative;
  padding: 100px 0px 120px;
}

.error-section:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.90);
}

.error-section .inner-section {
  position: relative;
  text-align: center;
}

.error-section .inner-section h1 {
  position: relative;
  color: #ffffff;
  font-size: 200px;
  line-height: 1em;
  font-weight: 700;
  margin-bottom: 10px;
}

.error-section .inner-section h2 {
  position: relative;
  font-size: 20px;
  color: #b8b8b8;
  font-weight: 400;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.error-section .inner-section .text {
  position: relative;
  color: #848484;
  font-size: 18px;
  margin-top: 6px;
  margin-bottom: 50px;
}

/*Search Box Widget*/

.error-search-form .form-group {
  position: relative;
  max-width: 430px;
  margin: 0 auto;
  width: 100%;
}

.error-search-form .form-group input[type="text"],
.error-search-form .form-group input[type="search"] {
  position: relative;
  line-height: 22px;
  background: none;
  display: block;
  font-size: 16px;
  width: 100%;
  height: 54px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  padding: 10px 50px 10px 20px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.error-search-form .form-group input:focus {
  border-color: #fd6502;
}

.error-search-form .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 54px;
  width: 60px;
  display: block;
  font-size: 16px;
  color: #ffffff;
  line-height: 100%;
  background: #f7b100;
  font-weight: normal;
}

/*** 

====================================================================
	Inventory Section Two
====================================================================

***/

.invent-style-two {
  position: relative;
}

.car-block-two {
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 40px;
}

.car-block-two .inner-box {
  position: relative;
}

.car-block-two .inner-box .image-column {
  position: relative;
  margin-bottom: 20px;
}

.car-block-two .inner-box .image {
  position: relative;
  text-align: center;
  border: 1px solid #f0f0f0;
}

.car-block-two .inner-box .content-column {
  position: relative;
  margin-bottom: 10px;
}

.car-block-two .inner-box .content-column h3 {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: -5px;
}

.car-block-two .inner-box .content-column h3 a {
  color: #232527;
  font-weight: 500;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.car-block-two .inner-box .content-column h3 a:hover {
  color: #ffd658;
}

.car-block-two .inner-box .content-column .price {
  position: relative;
  display: inline-block;
  color: #ffd658;
  font-size: 16px;
  font-weight: 500;
  padding: 3px 15px;
  line-height: 24px;
  margin-top: 8px;
  margin-left: 12px;
  background-color: #232527;
  font-family: 'Montserrat', sans-serif;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.car-block-two .inner-box .content-column .info-box {
  position: relative;
  padding: 20px 0px;
}

.car-block-two .inner-box .info-box .car-info {
  position: relative;
  font-family: 'Montserrat', sans-serif;
}

.car-block-two .inner-box .info-box .car-info li {
  position: relative;
  color: #232527;
  font-size: 14px;
  line-height: 20px;
  min-height: 40px;
  padding-left: 24px;
  font-weight: 600;
  padding-right: 12px;
  margin-right: 10px;
  display: inline-block;
  border-right: 1px solid #eaeaea;
}

.car-block-two .inner-box .info-box .car-info li .icon {
  position: absolute;
  line-height: 24px;
  color: #848484;
  font-size: 16px;
  left: 0px;
  top: 50%;
  margin-top: -12px;
}

.car-block-two .inner-box .info-box .car-info li .info-title {
  position: relative;
  display: block;
  font-weight: 400;
  color: #848484;
}

.car-block-two .inner-box .info-box .car-info li:last-child {
  margin-right: 0px;
  padding-right: 0px;
  border: 0px;
}

.car-block-two .inner-box .content-column .lower-box {
  position: relative;
  padding-top: 3px;
}

.car-block-two .inner-box .content-column .lower-box .btns-box {
  position: relative;
  float: left;
}

.car-block-two .inner-box .content-column .lower-box .btns-box li {
  position: relative;
  float: left;
  margin-right: 12px;
  margin-bottom: 10px;
}

.car-block-two .inner-box .content-column .lower-box .logos-box {
  position: relative;
  float: right;
}

.car-block-two .inner-box .content-column .lower-box .logos-box li {
  position: relative;
  float: left;
  margin-left: 20px;
  margin-bottom: 10px;
}

.car-block-two .inner-box .content-column .compare-check {
  position: absolute;
  right: 15px;
  top: 0px;
  z-index: 1;
}

.car-block-two .inner-box .content-column .compare-check label {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: #232527;
  padding-right: 10px;
  cursor: pointer;
}

.car-block-two .inner-box .content-column .compare-check input {
  position: relative;
  top: 2px;
}

.invent-style-two .lower-options {
  position: relative;
}

.invent-style-two .lower-options .styled-pagination {
  position: relative;
  float: left;
  text-align: left;
}

.invent-style-two .lower-options .styled-pagination ul li {
  float: left;
  margin: 0px 10px 10px 0px;
}

.invent-style-two .lower-options .compare-btn-box {
  position: relative;
  float: right;
}

.invent-style-two .lower-options .compare-btn-box .theme-btn {
  padding-left: 45px;
  padding-right: 45px;
}

/*** 

====================================================================
	Inventory Single
====================================================================

***/

.inventory-details {
  position: relative;
}

.schedule-drive-outer {
  position: relative;
  background: rgba(255, 84, 0, 0.90);
  padding: 65px 0px;
}

.inventory-details .product-carousel-outer .big-image-outer {
  position: relative;
}

.inventory-details .product-carousel-outer .big-image-outer .schedule-drive-outer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 65px 0px 20px;
  z-index: 10;
}

.schedule-drive-outer .form-outer {
  position: relative;
  max-width: 550px;
  margin: 0px auto;
  background: #ffffff;
}

.schedule-drive-outer .form-outer .form-header {
  position: relative;
  padding: 15px 15px;
  color: #ffffff;
  text-align: center;
  background: #232628;
}

.schedule-drive-outer .form-outer .form-header h2 {
  font-size: 26px;
  text-transform: capitalize;
  font-weight: 600;
}

.schedule-drive-outer .form-outer .form-header .vehicle-model {
  font-size: 14px;
}

.schedule-drive-outer .form-outer .form-header .hide-form-btn {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 20px;
  height: 20px;
  display: block;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}

.schedule-drive-outer .form-box {
  position: relative;
  padding: 25px 30px 18px;
}

.schedule-drive-outer .form-box .row {
  margin: 0px -10px;
}

.schedule-drive-outer .form-box .field-inner {
  position: relative;
  display: block;
}

.schedule-drive-outer .form-box .field-inner .fa {
  position: absolute;
  right: 12px;
  top: 0px;
  display: block;
  height: 46px;
  line-height: 47px;
  color: #f7b100;
  z-index: 1;
}

.schedule-drive-outer .form-box .row .form-group {
  padding: 0px 10px;
  margin-bottom: 12px;
}

.schedule-drive-outer .form-box .row .form-group input {
  background: #f7f7f7;
  line-height: 26px;
  height: 46px;
  font-size: 14px;
  padding: 10px 15px;
}

.schedule-drive-outer .form-box .row .form-group label {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

.schedule-drive-outer .form-box .row .form-group .theme-btn {
  margin-top: 8px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
}

.inventory-details .product-carousel-outer {
  position: relative;
  margin-bottom: 30px;
}

.inventory-details .product-carousel-outer img {
  display: block;
  width: 100%;
  height: auto;
  /* cursor:pointer; */
}

.inventory-details .product-carousel-outer .owl-nav,
.inventory-details .product-carousel-outer .owl-dots {
  display: none;
}

.inventory-details .product-carousel-outer .prod-image-carousel {
  position: relative;
  margin-bottom: 15px;
}

.inventory-details .product-carousel-outer .prod-image-carousel .lightbox-image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  color: #ffffff;
  z-index: 1;
  background: rgba(0, 0, 0, .70);
  opacity: 0;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;

}

.inventory-details .product-carousel-outer .prod-image-carousel .lightbox-image:hover {
  opacity: 1;
}

.inventory-details .product-carousel-outer .prod-image-carousel .lightbox-image .fa {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 32px;
  margin-left: -33px;
  margin-top: -33px;
  width: 66px;
  height: 66px;
  line-height: 66px;
}

.inventory-details .product-carousel-outer .prod-thumbs-carousel .owl-nav {
  display: block;
}

.inventory-details .product-carousel-outer .prod-thumbs-carousel .video-icon {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  color: #ffffff;
  text-align: center;
  z-index: 1;
}

.inventory-details .product-carousel-outer .prod-thumbs-carousel .video-icon .fa {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 12px;
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  line-height: 26px;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.inventory-details .product-carousel-outer .prod-thumbs-carousel .owl-next,
.inventory-details .product-carousel-outer .prod-thumbs-carousel .owl-prev {
  position: absolute;
  top: 0px;
  width: 34px;
  height: 100%;
  z-index: 2;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  background: rgba(0, 0, 0, 0.80);
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.inventory-details .product-carousel-outer .prod-thumbs-carousel .owl-next:hover,
.inventory-details .product-carousel-outer .prod-thumbs-carousel .owl-prev:hover {
  background: rgba(0, 0, 0, 0.95);
}

.inventory-details .product-carousel-outer .prod-thumbs-carousel .owl-prev {
  left: 0px;
}

.inventory-details .product-carousel-outer .prod-thumbs-carousel .owl-next {
  right: 0px;
}

.inventory-details .product-carousel-outer .prod-thumbs-carousel .owl-next .fa,
.inventory-details .product-carousel-outer .prod-thumbs-carousel .owl-prev .fa {
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
  width: 100%;
  height: 40px;
  line-height: 40px;
}

.inventory-details .vehicle-details {
  position: relative;
}

.inventory-details .vehicle-details .text-description {
  position: relative;
  /* margin-bottom:40px; */
  /* margin-bottom: 15px; */
}

.inventory-details .vehicle-details h2 {
  font-size: 26px;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-weight: 600;
  color: #232527;
}

.inventory-details .vehicle-details .images .image-column {
  position: relative;
  margin-bottom: 40px;
  text-align: center;
}

.inventory-details .details-panel-box {
  position: relative;
  margin-bottom: 50px;
  border: 1px solid #f0f0f0;
}

.inventory-details .details-panel-box .panel-header {
  position: relative;
}

.inventory-details .details-panel-box .panel-header .panel-btn {
  position: relative;
  display: block;
  padding: 10px 20px;
  line-height: 30px;
  color: #848484;
  background: #f7f7f7;
  cursor: pointer;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.inventory-details .details-panel-box:hover .panel-header .panel-btn {
  color: #f7b100;
}

.inventory-details .details-panel-box .panel-header h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-transform: capitalize;
  font-family: 'Open Sans', sans-serif;
}

.inventory-details .details-panel-box .panel-header h4 strong {
  font-weight: 600;
  color: #232527;
}

.inventory-details .details-panel-box .panel-header .arrow {
  position: absolute;
  right: 15px;
  top: 10px;
  line-height: 30px;
  font-size: 20px;
}

.inventory-details .details-panel-box .panel-content {
  position: relative;
  display: block;
  background: #ffffff;
}

.inventory-details .details-panel-box .panel-content .listing-outer {
  position: relative;
}

.inventory-details .details-panel-box .panel-content .list-column {
  position: relative;
  float: left;
  width: 50%;
}

.list-style-seven {
  position: relative;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.list-style-seven li {
  position: relative;
  line-height: 30px;
  padding: 10px 20px;
  border-bottom: 1px solid #f0f0f0;
}

.list-style-seven li:last-child {
  border: none;
}

.list-style-seven li .ttl,
.list-style-seven li .dtl {
  position: relative;
  float: left;
  display: block;
  width: 50%;
}

.list-style-seven li .dtl {
  color: #232527;
  font-weight: 600;
}

.tech-details .content-box {
  position: relative;
  padding-left: 120px;
}

.inventory-tabs .tab-buttons-outer {
  position: relative;
  float: left;
  margin-left: -120px;
  width: 120px;
  height: 100%;
  display: block;
  background: #f7f7f7;
  z-index: 1;
}

.inventory-tabs .tab-buttons {
  position: relative;
  width: 100%;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.inventory-tabs .tab-buttons .tab-btn {
  position: relative;
  width: 100%;
  display: block;
  line-height: 30px;
  padding: 20px 10px;
  cursor: pointer;
  font-weight: 400;
  border-top: 1px solid #ffffff;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.inventory-tabs .tab-buttons .tab-btn:after {
  content: '';
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -6px;
  border: 6px solid transparent;
  border-left: 7px solid #f7b100;
  opacity: 0;
}

.inventory-tabs .tab-buttons .tab-btn.active-btn {
  color: #ffffff;
  background: #f7b100;
}

.inventory-tabs .tab-buttons .tab-btn.active-btn:after {
  opacity: 1;
}

.inventory-tabs .tabs-content {
  position: relative;
  float: left;
  width: 100%;
}

.inventory-tabs .tabs-content .tab {
  position: relative;
  display: none;
}

.inventory-tabs .tabs-content .tab.active-tab {
  display: block;
}

.inventory-tabs .tabs-content .listing-outer {
  position: relative;
  padding: 25px 50px;
  margin: 0px -30px;
}

.inventory-tabs .tabs-content .listing-outer .list-column {
  position: relative;
  padding: 0px 30px;
}

.list-style-eight {
  position: relative;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.list-style-eight li {
  position: relative;
  color: #848484;
  font-weight: 500;
  line-height: 30px;
  padding: 6px 0px;
  padding-left: 25px;
}

.list-style-eight li:before {
  font-family: 'FontAwesome';
  content: "\f101";
  position: absolute;
  left: 0px;
  top: 6px;
  font-size: 16px;
  color: #f7b100;
  line-height: 30px;
}

.list-style-eight li .ttl,
.list-style-eight li .dtl {
  position: relative;
  display: block;
}

.list-style-eight li .dtl {
  color: #232527;
  font-weight: 600;
}

.inventory-details .extra-features .listing-outer {
  position: relative;
  padding: 15px 20px;
  margin: 0px -20px;
}

.inventory-details .extra-features .listing-outer .list-column {
  width: 33.333%;
  padding: 0px 20px;
}

.list-style-nine {
  position: relative;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.list-style-nine li {
  position: relative;
  color: #232527;
  font-weight: 500;
  line-height: 30px;
  padding: 3px 0px;
  padding-left: 25px;
}

.list-style-nine li:before {
  font-family: 'FontAwesome';
  content: "\f00c";
  position: absolute;
  left: 0px;
  top: 3px;
  font-size: 16px;
  color: #f7b100;
  line-height: 30px;
}

.inventory-details .offer-box {
  position: relative;
}

.inventory-details .offer-box .offer-column,
.inventory-details .offer-box .offer-banner {
  position: relative;
  margin-bottom: 30px;
}

.inventory-details .offer-box .offer-column .inner-box {
  position: relative;
  padding: 8px 20px 18px;
  background: #f7f7f7;
}

.inventory-details .offer-box .offer-column h3 {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
  border-bottom: 1px dashed #d0d0d0;
  margin-bottom: 20px;
}

.inventory-details .offer-box .offer-column .subtitle {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 2px 7px;
  background: #f7b100;
  color: #ffffff;
  font-weight: 600;
  border-radius: 3px;
  margin-bottom: 15px;
}

.inventory-details .offer-box .offer-column .offer-info {
  position: relative;
  padding-left: 15px;
}

.inventory-details .offer-box .offer-column .offer-info li {
  position: relative;
  line-height: 26px;
  margin-bottom: 15px;
}

.inventory-details .offer-box .offer-column .offer-info li:last-child {
  margin-bottom: 0px;
}

.inventory-details .offer-box .offer-column .offer-info li h4 {
  position: relative;
  line-height: 24px;
  font-size: 14px;
  color: #f7b100;
  font-weight: 600;
}

.inventory-details .offer-box .offer-banner .inner-box {
  position: relative;
}

.inventory-details .offer-box .offer-banner .image {
  position: relative;
}

.inventory-details .offer-box .offer-banner .image img {
  position: relative;
  display: block;
  width: 100%;
}

.inventory-details .offer-box .offer-banner .upper-info {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  color: #ffffff;
  padding: 22px 25px 0px;
}

.inventory-details .offer-box .offer-banner .upper-info h3 {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}

.inventory-details .offer-box .offer-banner .upper-info .text {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
}

.inventory-details .offer-box .offer-banner .upper-info .theme-btn {
  font-size: 14px;
  padding: 5px 15px;
  line-height: 24px;
  font-weight: 600;
}

.inventory-details .offer-box .offer-banner .limit {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  color: #ffffff;
  font-size: 12px;
  text-align: right;
  padding: 7px 15px;
}

/*Default Sidebar Title*/

.default-sidebar-title {
  position: relative;
  margin-bottom: 40px;
}

.default-sidebar-title h2 {
  font-size: 24px;
  color: #232527;
  font-weight: 600;
  line-height: 1.2em;
  padding-bottom: 14px;
  text-transform: capitalize;
}

.default-sidebar-title h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 50px;
  height: 2px;
  background-color: #f7b100;
}

.brochures-widget {
  position: relative;
  margin-bottom: 50px;
}

.brochures-widget .brochures-list {
  position: relative;
}

.brochures-widget .brochures-list li {
  position: relative;
  margin-bottom: 20px;
}

.brochures-widget .brochures-list li:last-child {
  margin-bottom: 0px;
}

.brochures-widget .brochures-list li a {
  position: relative;
  display: block;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  color: #232527;
  text-transform: capitalize;
  line-height: 30px;
  background: #f4f4f4;
}

.brochures-widget .brochures-list li a .fa {
  position: relative;
  top: 0px;
  color: #f7b100;
  padding-right: 7px;
}

.loan-cal-widget {
  position: relative;
  margin-bottom: 50px;
}

.loan-cal-widget .inner {
  position: relative;
  background: #ffffff;
  border: 1px solid #f0f0f0;
}

.loan-cal-widget .inner h3 {
  font-size: 18px;
  color: #232527;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 30px;
  padding: 10px 20px;
  border-bottom: 1px solid #f0f0f0;
}

.loan-cal-widget .form-box {
  padding: 12px 20px 5px;
}

.loan-cal-widget .form-box .cars-form .form-group label {
  font-family: 'Open Sans', sans-serif;
}

.loan-cal-widget .form-box .cars-form .theme-btn {
  margin-top: 5px;
}

.side-testi-widget {
  position: relative;
  margin-bottom: 50px;
}

.side-testi-widget .testimonials {
  position: relative;
}

.side-testi-widget .testimonials .upper-content {
  position: relative;
  padding: 30px 10px 25px 45px;
  background: #272727 url(../images/icons/curved-quote.png) left top no-repeat;
  color: #ffffff;
  font-size: 15px;
  line-height: 1.8em;
  border-radius: 2px;
  margin-bottom: 30px;
}

.side-testi-widget .testimonials .upper-content:after {
  content: '';
  position: absolute;
  left: 30px;
  top: 100%;
  border: 7px solid transparent;
  border-top: 10px solid #272727;
}

.side-testi-widget .testimonials .testi-info {
  position: relative;
  padding-top: 15px;
  padding-left: 80px;
  min-height: 75px;
}

.side-testi-widget .testimonials .testi-info .author-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 70px;
}

.side-testi-widget .testimonials .testi-info h4 {
  color: #272727;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
}

.side-testi-widget .testimonials .testi-info .designation {
  font-size: 15px;
  color: #848484;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.side-testi-widget .testimonials .testi-info .rating .fa {
  font-size: 14px;
  color: #fc721e;
  margin-right: 5px;
}

.side-testi-widget .testimonials .owl-nav,
.side-testi-widget .testimonials .owl-dots {
  display: none !important;
}

/*** 

====================================================================
	Cars Comparison Section
====================================================================

***/

.cars-comparison-section {
  position: relative;
  padding: 70px 0px;
}

.cars-comparison-section .comparison-box {
  position: relative;
  overflow-x: auto;
}

.cars-comparison-section .comparison-box .compare-outer {
  position: relative;
  min-width: 1080px;
}

.cars-comparison-section .title-column {
  position: relative;
  float: left;
  width: 25%;
}

.cars-comparison-section .title-column h2 {
  position: relative;
  font-weight: 700;
  color: #232527;
  font-size: 24px;
  padding-bottom: 15px;
  margin-bottom: 34px;
  text-transform: uppercase;
}

.cars-comparison-section .title-column h2:after {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 55px;
  height: 2px;
  background-color: #f7b100;
}

.cars-comparison-section .title-column .select-form {
  position: relative;
  margin-bottom: 65px;
}

.cars-comparison-section h3 {
  color: #232527;
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.cars-comparison-section .select-form .form-group {
  position: relative;
  margin-bottom: 10px;
}

.cars-comparison-section .select-form .form-group .select-box {
  position: relative;
}

.cars-comparison-section .select-form .form-group .select-box label {
  position: relative;
  font-size: 14px;
  color: #232527;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 0px;
  padding-left: 30px;
  line-height: 1.3em;
  font-family: 'Open Sans', sans-serif;
}

.cars-comparison-section .select-form .form-group .select-box input[type="checkbox"] {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 16px;
  height: 16px;
  margin: 0px;
  visibility: hidden;
  background-color: #ffffff;
}

.cars-comparison-section .select-form .form-group .select-box .default-check {
  position: absolute;
  left: 0px;
  top: 1px;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 1px solid #cfcfcf;
}

.cars-comparison-section .select-form .form-group .select-box .check-icon {
  position: absolute;
  content: '\f00c';
  left: 0px;
  top: 0px;
  width: 16px;
  height: 16px;
  color: #f7b100;
  z-index: 99;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  opacity: 0;
  font-family: 'FontAwesome';
}

.cars-comparison-section .select-form .form-group .select-box input[type="checkbox"]:checked+label .check-icon {
  opacity: 1;
}

.cars-comparison-section .car-compare-header .car-column {
  position: relative;
  padding: 0px 0px;
  float: left;
  width: 25%;
}

.cars-comparison-section .car-compare-header .car-column .inner {
  position: relative;
  border: 1px solid #f0f0f0;
  border-bottom: none;
  text-align: center;
}

.cars-comparison-section .car-compare-header .car-column:nth-child(3) .inner,
.cars-comparison-section .car-compare-header .car-column:nth-child(4) .inner {
  border-left: none;
}

.cars-comparison-section .car-compare-header .car-column .image {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
}

.cars-comparison-section .car-compare-header .car-column .title-box {
  position: relative;
  padding: 18px 15px 15px;
}

.cars-comparison-section .car-compare-header .car-column .title-box h4 {
  font-size: 14px;
  text-transform: uppercase;
  color: #232628;
  font-weight: 700;
  margin-bottom: 3px;
}

.cars-comparison-section .car-compare-header .car-column .title-box .price {
  font-size: 16px;
  text-transform: uppercase;
}

.cars-comparison-section .car-info-block {
  position: relative;
  margin-bottom: 50px;
}

.cars-comparison-section .car-info-block:last-child {
  margin-bottom: 0px;
}

.cars-comparison-section .car-info-block .info-inner {
  position: relative;
  border: 1px solid #f0f0f0
}

.cars-comparison-section .car-info-block .info-row {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  overflow: hidden;
}

.cars-comparison-section .car-info-block .info-inner .info-row:last-child {
  border-bottom: none;
}

.cars-comparison-section .car-info-block .info-row .info-col {
  position: relative;
  float: left;
  width: 25%;
  line-height: 24px;
  padding: 12px 15px;
}

.cars-comparison-section .car-info-block .info-row .info-col:after {
  content: '';
  position: absolute;
  right: 0px;
  top: 0px;
  height: 400px;
  border-right: 1px solid #f0f0f0;
}

.cars-comparison-section .car-info-block .info-row .info-col:first-child {
  color: #232527;
  font-weight: 500;
}

.cars-comparison-section .car-info-block .info-row .info-col:last-child {
  border: none;
}

.cars-comparison-section .car-info-block .info-row .info-col:last-child:after {
  display: none;
}

.cars-comparison-section .car-info-block .info-row .info-col .fa-check {
  color: #f7b100;
}


.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  color: #1b1818 !important;
  text-decoration: none !important;
  background-color: #ffce33 !important;
  outline: 0 !important;
  font-size: 12px !important;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  width: 226px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 11px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 1px !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.dropdown-menu::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(168, 168, 168, 1) !important; */
  background-color: #ffffff;
  border-radius: 3px;
}

.dropdown-menu::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 3px !important;
}

.dropdown-item:active {
  color: #000000 !important;
  text-decoration: none;
  background-color: #ffce33 !important;
  font-size: 12px !important;
  font-family: 'Read-Regular' !important;
}

.dropdown-item.active {
  color: #000000 !important;
  text-decoration: none;
  background-color: #ffce33 !important;
  font-size: 12px !important;
  font-family: 'Read-Regular' !important;
}

.alert {
  padding: 5px;
  /* margin-bottom: 0px; */
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
}
