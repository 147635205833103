@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

.fa-spin {
  -webkit-animation: fa-spin 0.75s infinite linear !important;
  animation: fa-spin 0.75s infinite linear !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}
